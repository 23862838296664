import Api from "@griffingroupglobal/eslib-api";
import { AUTH_TOKEN_STORAGE_KEY } from "../constants";

let requestInterceptor;

export default () => {
  // Clean up any existing interceptor
  if (requestInterceptor) {
    Api.interceptors.request.eject(requestInterceptor);
  }

  // Add the x-with-compression header to all requests
  requestInterceptor = Api.interceptors.request.use((request) => {
    const newRequest = { ...request };
    newRequest.headers["x-with-compression"] = "true";
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    newRequest.headers.Authorization = `Bearer ${authToken}`;
    newRequest.headers["Content-Type"] = "application/json";
    return newRequest;
  });
};
