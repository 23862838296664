import React from "react";
import { NavigationProvider } from "@griffingroupglobal/lumina-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { SettingAPI } from "@griffingroupglobal/eslib-api";
import { AUTH_TOKEN_STORAGE_KEY, TENANT_ID_STORAGE_KEY } from "../../constants";

function LilyProvider({ children }) {
  const { LILY_API_HOST } = window.runConfig;
  const history = useHistory();
  const params = useParams();

  const onBeforeChat = async () => {
    await SettingAPI.get();
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);
    return { authToken, tenantId };
  };

  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const tenantId = localStorage.getItem(TENANT_ID_STORAGE_KEY);

  return (
    <NavigationProvider
      setNavigate={(to, options) => history.push(to, options)}
      setUseParams={() => params}
      setUseLocation={useLocation}
      LILY_API_HOST={LILY_API_HOST}
      onBeforeChat={onBeforeChat}
      authToken={authToken}
      tenantId={tenantId}
    >
      {children}
    </NavigationProvider>
  );
}

export default LilyProvider;
