import { useReducer } from "react";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

const defaultState = {};

const reducer = (configuration, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.configuration) ?? defaultState;
    case "addSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: [
          ...configuration?.[action.selectionValue],
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "removeSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].filter(
          (item, idx) => idx !== action.index
        ),
      };
    }
    case "changeSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (p, idx) => {
            if (idx === action.index) {
              return {
                ...p,
                display: action.value,
              };
            }
            return p;
          }
        ),
      };
    }
    case "changeSectionItemEditing": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item, idx) => {
            if (idx === action.index) {
              return {
                ...item,
                isEditing: !item.isEditing,
              };
            }
            return item;
          }
        ),
      };
    }
    case "selectAllSectionItems": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item) => {
            return {
              ...item,
              selected: !item.count ? action.value : item.selected,
            };
          }
        ),
      };
    }
    case "selectSectionItem": {
      return {
        ...configuration,
        [action.selectionValue]: configuration?.[action.selectionValue].map(
          (item, idx) => {
            if (idx === action.index) {
              return {
                ...item,
                selected: !item.selected,
              };
            }
            return item;
          }
        ),
      };
    }
    case "addPropertySpaceType": {
      return {
        ...configuration,
        propertySpaceTypes: [
          ...configuration.propertySpaceTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "addPropertyLevelType": {
      return {
        ...configuration,
        propertyLevelTypes: [
          ...configuration.propertyLevelTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "addProjectInspectionType": {
      return {
        ...configuration,
        projectInspectionTypes: [
          ...configuration.projectInspectionTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "addPropertyType": {
      return {
        ...configuration,
        propertyTypes: [
          ...configuration.propertyTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "addProjectType": {
      return {
        ...configuration,
        projectTypes: [
          ...configuration.projectTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "addEntityType": {
      return {
        ...configuration,
        entityTypes: [
          ...configuration.entityTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
          },
        ],
      };
    }
    case "removePropertySpaceType": {
      return {
        ...configuration,
        propertySpaceTypes: configuration.propertySpaceTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "removePropertyLevelType": {
      return {
        ...configuration,
        propertyLevelTypes: configuration.propertyLevelTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "removeProjectInspectionType": {
      return {
        ...configuration,
        projectInspectionTypes: configuration.projectInspectionTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "removeProjectType": {
      return {
        ...configuration,
        projectTypes: configuration.projectTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "removePropertyType": {
      return {
        ...configuration,
        propertyTypes: configuration.propertyTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "removeEntityType": {
      return {
        ...configuration,
        entityTypes: configuration.entityTypes.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "changePropertySpaceType": {
      return {
        ...configuration,
        propertySpaceTypes: configuration.propertySpaceTypes.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changePropertyLevelType": {
      return {
        ...configuration,
        propertyLevelTypes: configuration.propertyLevelTypes.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changeProjectInspectionType": {
      return {
        ...configuration,
        projectInspectionTypes: configuration.projectInspectionTypes.map(
          (p, idx) => {
            if (idx === action.index) {
              return {
                ...p,
                display: action.value,
              };
            }
            return p;
          }
        ),
      };
    }
    case "changePropertyType": {
      return {
        ...configuration,
        propertyTypes: configuration.propertyTypes.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changeProjectTypeEditing": {
      return {
        ...configuration,
        projectTypes: configuration.projectTypes.map((type, idx) => {
          if (idx === action.index) {
            return {
              ...type,
              isEditing: !type.isEditing,
            };
          }
          return type;
        }),
      };
    }
    case "changeProjectInspectionTypeEditing": {
      return {
        ...configuration,
        projectInspectionTypes: configuration.projectInspectionTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                isEditing: !type.isEditing,
              };
            }
            return type;
          }
        ),
      };
    }
    case "changePropertySpaceTypeEditing": {
      return {
        ...configuration,
        propertySpaceTypes: configuration.propertySpaceTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                isEditing: !type.isEditing,
              };
            }
            return type;
          }
        ),
      };
    }
    case "changePropertyLevelTypeEditing": {
      return {
        ...configuration,
        propertyLevelTypes: configuration.propertyLevelTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                isEditing: !type.isEditing,
              };
            }
            return type;
          }
        ),
      };
    }
    case "changeProjectType": {
      return {
        ...configuration,
        projectTypes: configuration.projectTypes.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changeEntityType": {
      return {
        ...configuration,
        entityTypes: configuration.entityTypes.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changePropertyTypeEditing": {
      return {
        ...configuration,
        propertyTypes: configuration.propertyTypes.map((type, idx) => {
          if (idx === action.index) {
            return {
              ...type,
              isEditing: !type.isEditing,
            };
          }
          return type;
        }),
      };
    }
    case "selectPropertySpaceType": {
      return {
        ...configuration,
        propertySpaceTypes: configuration.propertySpaceTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                selected: !type.selected,
              };
            }
            return type;
          }
        ),
      };
    }
    case "selectPropertyLevelType": {
      return {
        ...configuration,
        propertyLevelTypes: configuration.propertyLevelTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                selected: !type.selected,
              };
            }
            return type;
          }
        ),
      };
    }
    case "selectProjectInspectionType": {
      return {
        ...configuration,
        projectInspectionTypes: configuration.projectInspectionTypes.map(
          (type, idx) => {
            if (idx === action.index) {
              return {
                ...type,
                selected: !type.selected,
              };
            }
            return type;
          }
        ),
      };
    }
    case "selectEntityType": {
      return {
        ...configuration,
        entityTypes: configuration.entityTypes.map((type, idx) => {
          if (idx === action.index) {
            return {
              ...type,
              selected: !type.selected,
            };
          }
          return type;
        }),
      };
    }
    case "selectProjectType": {
      return {
        ...configuration,
        projectTypes: configuration.projectTypes.map((type, idx) => {
          if (idx === action.index) {
            return {
              ...type,
              selected: !type.selected,
            };
          }
          return type;
        }),
      };
    }
    case "selectPropertyType": {
      return {
        ...configuration,
        propertyTypes: configuration.propertyTypes.map((type, idx) => {
          if (idx === action.index) {
            return {
              ...type,
              selected: !type.selected,
            };
          }
          return type;
        }),
      };
    }
    case "selectAllPropertySpaceTypes": {
      return {
        ...configuration,
        propertySpaceTypes: configuration.propertySpaceTypes.map((type) => {
          return {
            ...type,
            selected: action.value,
          };
        }),
      };
    }
    case "selectAllPropertyLevelTypes": {
      return {
        ...configuration,
        propertyLevelTypes: configuration.propertyLevelTypes.map((type) => {
          return {
            ...type,
            selected: action.value,
          };
        }),
      };
    }
    case "selectAllProjectInspectionTypes": {
      return {
        ...configuration,
        projectInspectionTypes: configuration.projectInspectionTypes.map(
          (type) => {
            return {
              ...type,
              selected: action.value,
            };
          }
        ),
      };
    }
    case "selectAllEntityTypes": {
      return {
        ...configuration,
        entityTypes: configuration.entityTypes.map((type) => {
          return {
            ...type,
            selected: action.value,
          };
        }),
      };
    }
    case "selectAllPropertyTypes": {
      return {
        ...configuration,
        propertyTypes: configuration.propertyTypes.map((type) => {
          return {
            ...type,
            selected: action.value,
          };
        }),
      };
    }
    case "selectAllProjectTypes": {
      return {
        ...configuration,
        projectTypes: configuration.projectTypes.map((type) => {
          return {
            ...type,
            selected: action.value,
          };
        }),
      };
    }
    case "addEditingEntityInsuranceCategory": {
      return {
        ...configuration,
        entityInsurance: [
          ...configuration.entityInsurance,
          {
            category: "",
            policies: [],
            isEditing: true,
            custom: true,
          },
        ],
      };
    }
    case "addEditingProjectInsuranceCategory": {
      return {
        ...configuration,
        projectInsurance: [
          ...configuration.projectInsurance,
          {
            category: "",
            policies: [],
            isEditing: true,
            custom: true,
          },
        ],
      };
    }
    case "toggleEditingEntityInsuranceCategory": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              isEditing: !entity.isEditing,
            };
          }
          return entity;
        }),
      };
    }
    case "toggleEditingProjectInsuranceCategory": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                isEditing: !project.isEditing,
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsuranceCategory": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              category: action.value,
            };
          }
          return entity;
        }),
      };
    }
    case "removeProjectInsuranceCategory": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "changeProjectInsuranceCategory": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                category: action.value,
              };
            }
            return project;
          }
        ),
      };
    }
    case "toggleEntityInsurancePolicy": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    isOpen: !policy.isOpen,
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "toggleProjectInsurancePolicy": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      isOpen: !policy.isOpen,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "toggleEditingEntityInsurancePolicy": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    isEditing: !policy.isEditing,
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "removeProjectInsurancePolicy": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, catIndex) => {
            if (catIndex === action.pIndex) {
              return {
                ...project,
                policies: project?.policies.filter(
                  (type, idx) => idx !== action.polIndex
                ),
              };
            }
            return project;
          }
        ),
      };
    }
    case "toggleEditingProjectInsurancePolicy": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      isEditing: !policy.isEditing,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "addEntityInsurancePolicy": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: [
                ...entity.policies,
                {
                  provider: "",
                  startDate: new Date(),
                  duration: "annual",
                  website: "",
                  expense: {
                    classifications: {
                      values: [],
                    },
                  },
                  isEditing: true,
                  isOpen: true,
                },
              ],
            };
          }
          return entity;
        }),
      };
    }
    case "addProjectInsurancePolicy": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: [
                  ...project.policies,
                  {
                    provider: "",
                    startDate: new Date(),
                    duration: "annual",
                    website: "",
                    cost: 0,
                    isEditing: true,
                    isOpen: true,
                  },
                ],
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsurancePolicyDate": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    startDate: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeProjectInsurancePolicyDate": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      startDate: action.value,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsurancePolicyDuration": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    duration: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeProjectInsurancePolicyDuration": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      duration: action.value,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsurancePolicyWebsite": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    website: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeProjectInsurancePolicyWebsite": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      website: action.value,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsurancePolicyProvider": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    provider: action.value,
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeProjectInsurancePolicyProvider": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      provider: action.value,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsurancePolicyExpense": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (action.policyIndex === pIndex) {
                  return {
                    ...policy,
                    expense: {
                      classifications: {
                        ...policy?.expense?.classifications,
                        selected: action.value === "classifications",
                      },
                      percentage: {
                        ...policy?.expense?.percentage,
                        selected: action.value === "percentage",
                      },
                      cost: {
                        ...policy?.expense?.cost,
                        selected: action.value === "cost",
                      },
                    },
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeEntityInsurancePolicyPercentage": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    expense: {
                      ...policy.expense,
                      percentage: {
                        ...policy.expense.percentage,
                        value: action.value,
                      },
                    },
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeEntityInsurancePolicyCost": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    expense: {
                      ...policy.expense,
                      cost: {
                        ...policy.expense.cost,
                        value: action.value,
                      },
                    },
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeProjectInsurancePolicyCost": {
      return {
        ...configuration,
        projectInsurance: configuration.projectInsurance.map(
          (project, pIndex) => {
            if (pIndex === action.projectIndex) {
              return {
                ...project,
                policies: project.policies.map((policy, polIndex) => {
                  if (polIndex === action.policyIndex) {
                    return {
                      ...policy,
                      cost: action.value,
                    };
                  }
                  return policy;
                }),
              };
            }
            return project;
          }
        ),
      };
    }
    case "changeEntityInsurancePolicyClassificationCost": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    expense: {
                      ...policy.expense,
                      classifications: {
                        ...policy.expense.classifications,
                        values: policy.expense.classifications.values.map(
                          (value, cIndex) => {
                            if (cIndex === action.classificationIndex) {
                              return {
                                ...value,
                                cost: action.value,
                              };
                            }
                            return value;
                          }
                        ),
                      },
                    },
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "changeEntityInsurancePolicyClassification": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    expense: {
                      ...policy.expense,
                      classifications: {
                        ...policy.expense.classifications,
                        values: policy.expense.classifications.values.map(
                          (value, cIndex) => {
                            if (cIndex === action.classificationIndex) {
                              return {
                                ...value,
                                classification: action.value,
                              };
                            }
                            return value;
                          }
                        ),
                      },
                    },
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }
    case "addEntityInsurancePolicyClassification": {
      return {
        ...configuration,
        entityInsurance: configuration.entityInsurance.map((entity, eIndex) => {
          if (eIndex === action.entityIndex) {
            return {
              ...entity,
              policies: entity.policies.map((policy, pIndex) => {
                if (pIndex === action.policyIndex) {
                  return {
                    ...policy,
                    expense: {
                      ...policy.expense,
                      classifications: {
                        ...policy.expense.classifications,
                        values: [
                          ...policy.expense.classifications.values,
                          { classification: "", cost: "0" },
                        ],
                      },
                    },
                  };
                }
                return policy;
              }),
            };
          }
          return entity;
        }),
      };
    }

    case "changeContactDisciplineEditing": {
      return {
        ...configuration,
        contactDisciplines: configuration.contactDisciplines.map(
          (discipline, idx) => {
            if (idx === action.index) {
              return {
                ...discipline,
                isEditing: !discipline.isEditing,
              };
            }
            return discipline;
          }
        ),
      };
    }
    case "removeContactDiscipline": {
      return {
        ...configuration,
        contactDisciplines: configuration.contactDisciplines.filter(
          (discipline, idx) => idx !== action.index
        ),
      };
    }
    case "selectContactDiscipline": {
      return {
        ...configuration,
        contactDisciplines: configuration.contactDisciplines.map(
          (discipline, idx) => {
            if (idx === action.index) {
              return {
                ...discipline,
                selected: !discipline.selected,
              };
            }
            return discipline;
          }
        ),
      };
    }
    case "changeContactDiscipline": {
      return {
        ...configuration,
        contactDisciplines: configuration.contactDisciplines.map(
          (discipline, idx) => {
            if (idx === action.index) {
              return {
                ...discipline,
                display: action.value,
              };
            }
            return discipline;
          }
        ),
      };
    }
    case "addContactDiscipline": {
      return {
        ...configuration,
        contactDisciplines: [
          ...configuration.contactDisciplines,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }

    case "toggleAssetCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((type, idx) => {
          if (idx === action.index) {
            return {
              ...type,
              isOpen: !type.isOpen,
            };
          }
          return {
            ...type,
            isOpen: false,
          };
        }),
      };
    }
    case "addAssetCategory": {
      return {
        ...configuration,
        assetCategories: [
          ...configuration.assetCategories,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
            subcategories: [],
          },
        ],
      };
    }
    case "changeAssetCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              display: action.value,
            };
          }
          return p;
        }),
      };
    }
    case "changeAssetCategoryEditing": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((p, idx) => {
          if (idx === action.index) {
            return {
              ...p,
              isEditing: !p.isEditing,
            };
          }
          return p;
        }),
      };
    }
    case "selectAssetCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((cat, idx) => {
          if (idx === action.index) {
            return {
              ...cat,
              selected: !cat.selected,
              subcategories: cat?.subcategories?.map((subcat) => {
                if (cat.selected) {
                  return {
                    ...subcat,
                    selected: false,
                  };
                }

                return subcat;
              }),
            };
          }
          return cat;
        }),
      };
    }
    case "addAssetSubCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((cat, catIndex) => {
          if (catIndex === action.index) {
            return {
              ...cat,
              subcategories: [
                ...cat.subcategories,
                {
                  display: "",
                  id: uuidv4(),
                  isEditing: true,
                  selected: true,
                },
              ],
            };
          }
          return cat;
        }),
      };
    }
    case "removeAssetCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.filter(
          (type, idx) => idx !== action.index
        ),
      };
    }
    case "changeAssetSubCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((cat, catIndex) => {
          if (catIndex === action.index) {
            return {
              ...cat,
              subcategories: cat.subcategories.map((type, typeIndex) => {
                if (typeIndex === action.typeIndex) {
                  return {
                    ...type,
                    display: action.value,
                  };
                }

                return type;
              }),
            };
          }
          return cat;
        }),
      };
    }
    case "removeAssetSubCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((cat, catIndex) => {
          if (catIndex === action.index) {
            return {
              ...cat,
              subcategories: cat?.subcategories.filter(
                (type, idx) => idx !== action.typeIndex
              ),
            };
          }
          return cat;
        }),
      };
    }
    case "selectAssetSubCategory": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((cat, catIndex) => {
          if (catIndex === action.index) {
            return {
              ...cat,
              subcategories: cat.subcategories.map((subcat, typeIndex) => {
                if (typeIndex === action.cIndex) {
                  return {
                    ...subcat,
                    selected: !subcat.selected,
                  };
                }

                return subcat;
              }),
            };
          }
          return cat;
        }),
      };
    }
    case "changeAssetSubCategoryEditing": {
      return {
        ...configuration,
        assetCategories: configuration.assetCategories.map((cat, catIndex) => {
          if (catIndex === action.index) {
            return {
              ...cat,
              subcategories: cat.subcategories.map((type, typeIndex) => {
                if (typeIndex === action.typeIndex) {
                  return {
                    ...type,
                    isEditing: !type.isEditing,
                  };
                }

                return type;
              }),
            };
          }
          return cat;
        }),
      };
    }
    case "toggleTaskCompletionTimeRequired": {
      return {
        ...configuration,
        isCompletionTimeRequired: !configuration?.isCompletionTimeRequired,
      };
    }
    case "addSubmittalType": {
      return {
        ...configuration,
        submittalTypes: [
          ...configuration.submittalTypes,
          {
            display: "",
            id: uuidv4(),
            selected: true,
            custom: true,
            isEditing: true,
          },
        ],
      };
    }
    case "changeSubmittalType": {
      return {
        ...configuration,
        submittalTypes: configuration.submittalTypes.map(
          (submittalType, idx) => {
            if (idx === action.index) {
              return {
                ...submittalType,
                display: action.value,
              };
            }
            return submittalType;
          }
        ),
      };
    }
    case "removeSubmittalType": {
      return {
        ...configuration,
        submittalTypes: configuration.submittalTypes.filter(
          (submittalType, idx) => idx !== action.index
        ),
      };
    }
    case "selectSubmittalType": {
      return {
        ...configuration,
        submittalTypes: configuration.submittalTypes.map(
          (submittalType, idx) => {
            if (idx === action.index) {
              return {
                ...submittalType,
                selected: !submittalType.selected,
              };
            }
            return submittalType;
          }
        ),
      };
    }
    case "changeSubmittalTypeEditing": {
      return {
        ...configuration,
        submittalTypes: configuration.submittalTypes.map(
          (submittalType, idx) => {
            if (idx === action.index) {
              return {
                ...submittalType,
                isEditing: !submittalType.isEditing,
              };
            }
            return submittalType;
          }
        ),
      };
    }
    case "changeSubmittalDefaultTemplate": {
      return {
        ...configuration,
        submittalTemplate: action.payload,
      };
    }
    case "changeWorkflowDefaultTemplate": {
      return {
        ...configuration,
        workflowTemplate: action.payload,
      };
    }
    case "changeRFIDefaultTemplate": {
      return {
        ...configuration,
        RFITemplate: action.payload,
      };
    }
    case "addStepRFITemplate": {
      return {
        ...configuration,
        RFITemplate: {
          ...configuration?.RFITemplate,
          steps: [...configuration?.RFITemplate?.steps, action.step],
        },
      };
    }
    case "removeStepRFITemplate": {
      return {
        ...configuration,
        RFITemplate: {
          ...configuration.RFITemplate,
          steps: configuration?.RFITemplate?.steps.filter(
            (_, index) => index !== action.index
          ),
        },
      };
    }
    case "changeDurationStepRFITemplate": {
      const clonedConfiguration = cloneDeep(configuration);
      clonedConfiguration.RFITemplate.steps[action.index].duration.projected =
        action.duration;
      return clonedConfiguration;
    }
    case "changeRoleStepRFITemplate": {
      const clonedConfiguration = cloneDeep(configuration);
      clonedConfiguration.RFITemplate.steps[action.index].type = action.role;
      return clonedConfiguration;
    }
    case "toggleLilyOnOff": {
      const updatedConfig = {
        ...configuration,
        lily: {
          ...configuration.lily,
          isEnabled: !configuration?.lily?.isEnabled || false,
        },
      };
      return updatedConfig;
    }
    default:
      return configuration;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};
