import moment from "moment";
import * as yup from "yup";
import { guessTimeZone } from "./helpers/Time";

export const DEFAULT_QUERIES_CACHE_TIME = 5 * 60 * 1000;
export const DEFAULT_QUERIES_STALE_TIME = 20 * 1000;

// password invalid characters

// password special characters
export const PASSWORD_SPECIAL_CHARS = `[!]_(),^.$;+=-@\`\\~"`;

// vars
export const AUTH_TOKEN_STORAGE_KEY = "authToken";
export const REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
export const TENANT_ID_STORAGE_KEY = "tenantId";
export const REMEMBER_ME_STATUS = "rememberMe";
export const USER_EMAIL = "email";

export const ASSET_CATEGORY_VALUESET_NAME = "assetcategory";
export const GET_ASSET_SUBCATEGORY_VALUESET_NAME = (catCode) =>
  `${catCode}-subcategory`;
export const ASSET_VALUE_VALUESET_NAME = "asset-value-type";

export const BUILDING_TYPES_VALUESET_NAME = "building-types";

export const CHAT_WINDOW_OPEN_WIDTH = 400;
export const MOMENT_UTC_ES_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
export const MOMENT_CALENDAR_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

// Style
export const BRAND_GREEN = "#1DC29D";
export const BORDER_GREEN = "#1CC39D";
export const BRAND_GREY = "#C5C5C5";
export const BRAND_GREEN_LIGHT = "#1DC29D41";
export const BRAND_PURPLE = "#555DD0";
export const BRAND_RED = "#E40202"; // This Matches brandRed in tailwindConfig also used for error message & border on Input.jsx
export const MARKED_COMPLETE_BLUE = "#076ccb4a";
export const MARKED_COMPLETE_GRAY = "#333333";
export const PILL_GRAY = "#585858";

export const DROPDOWN_STYLE = {
  valueContainer: {
    paddingLeft: "1rem",
  },
  control: {
    "&:hover": {
      border: "1",
    },
    boxShadow: "unset",
  },
  placeholder: {
    fontSize: "1rem",
    opacity: 0.7,
    fontWeight: 400,
    whiteSpace: "nowrap",
    letterSpacing: "1px",
  },
  singleValue: {
    color: "#6a6a6a",
  },
};
export const TABLE_COLUMN_WIDTH = 60;

// app state actions
export const SET_MAIN_REF = "Hook/setMainContainerRef";
export const SET_IS_SIGNED_IN = "Auth/setIsSignedIn";
export const SET_IS_SITE_MAINTENANCE = "Auth/setIsSiteMaintenance";
export const SET_CURRENT_USER = "Auth/currentUser";
export const LOGOUT_USER = "Auth/logout";
export const CONNECT_STREAM_USER = "Auth/connectStreamUser";
export const ADD_STREAM_CHANNEL = "Hook/addStreamChannel";
export const SET_PROPERTY_ASSET_TOTALS = "Hook/setPropertyAssetTotals";
export const SET_PROPERTIES = "Hook/setProperties";
export const SET_PROPERTIES_DICT = "Hook/setPropertiesDict";
export const SET_PROPERTIES_DD = "Hook/setPropertiesDd";
export const SET_DOCUMENTS = "Hook/setDocuments";
export const SET_ASSOCIATED_FILES = "Hook/setAssociatedFiles";

export const SET_DOCUMENTS_DICT = "Hook/setDocumentsDict";
export const SET_EVENTS = "Hook/setEvents";
export const SET_ALL_UNFORMATTED_EVENTS = "Hook/setAllUnformattedEvents";
export const SET_MAINTENANCE_TASKS = "Hook/setMaintenanceTasks";
export const SET_USER_EVENTS_DICT = "Hook/setUserEvents";
export const SET_USER_EVENTS = "SET_USER_EVENTS";
export const UPDATE_USER_EVENTS_DICT = "Hook/updateUserEvents";
export const REMOVE_USER_EVENT = "Hook/removeUserEvent";
export const REMOVE_ASSOCIATED_EVENT = "Hook/removeAssociatedEvent";
export const UPDATE_ASSOCIATED_EVENTS_DICT = "Hook/updateAssociatedEvents";
export const SET_ASSOCIATED_EVENTS_DICT = "Hook/setAssociatedEvents";
export const SET_ALL_EVENTS_DICT = "Hook/setAllEventsDict";
export const PUSH_USER_EVENTS_DICT = "Hook/pushUserEvents";
export const SET_ASSETS = "Hook/setAssets";

export const SET_BUDGETS = "Hook/setBudgets";
export const SET_BUDGET_LINE_ITEMS = "Hook/setBudgetLineItems";
export const SET_BUDGET_DETAILS = "Hook/setBudgetDetails";
export const SET_BUDGET_GROUPS = "Hook/setBudgetGroups";
export const SET_BUDGET_TYPE = "Hook/setBudgetType";
export const SET_BUDGET_LOCATIONS = "Hook/setBudgetLocations";
export const SET_BUDGET_LOCK_GMP = "Hook/setBudgetLockGmp";
export const SET_BUDGET_LAST_UPDATED = "Hook/setBudgetLastUpdated";
export const SET_BUDGET_LOCK_FIXED_FIRM = "Hook/setBudgetLockFixedFirm";
export const SET_WATCHLIST = "Hook/setWatchList";
export const SET_MEASUREMENTS = "Hook/setMeasurements";
export const SET_ATTRIBUTES = "Hook/setAttributes";
export const SET_ASSET_CATEGORIES = "Hook/setAssetCategories";
export const SET_ASSET_SUBCATEGORIES = "Hook/setAssetSubcategories";
export const SET_CLIENTS = "Hook/setClients";
export const SET_FAVORITES = "Hook/setFavorites";
export const SET_TIMESHEET = "Hook/setTimeSheet";
export const SET_TIMESHEET_APPROVAL = "Hook/setTimeSheetApproval";
export const SET_PROJECTS = "Hook/setProjects";
export const SET_PROJECTS_CODES = "Hook/setProjectsCodes";
export const SET_PROJECT_DICT = "Hook/setProjectDict";
export const SET_PROJECT_DD = "Hook/setProjectDD";

export const SET_COMPANIES = "Hook/setCompanies";
export const SET_ADMIN_SETTINGS = "Hook/setAdminSettings";
export const SET_SETTINGS = "Hook/setSettings";
export const SET_FINANCIALS_ACTUAL = "Hook/setFinancialsActual";
export const SET_MESSAGE_SEARCH_KEYWORD = "Hook/setMessageSearchKeyword";
export const SET_MESSAGE_FILTER_KEYWORD = "Hook/setMessageFilterKeyword";
export const SET_USERS = "Hook/setUsers";
export const SET_EMPLOYEES = "Hook/setEmployees";
export const SET_ALL_USERS = "Hook/setAllUsers";
export const SET_USER_DICT = "Hook/setUserDict";
export const UPDATE_USERS = "Hook/updateUserDict";
export const SET_USERS_HOOK_STATE = "Hook/setUsersHookState";
export const SET_DIRECT_REPORTS = "Hook/setDirectReports";
export const SET_DIRECT_REPORT_OPTIONS = "Hook/setDirectReportOptions";
export const SET_ROLES = "Hook/setRoles";
export const SET_ROLES_DICT = "Hook/setRolesDict";
export const SET_ROLES_WOP = "Hook/setRolesWOP";
export const SET_WORKFLOWS = "Hook/setWorkflows";
export const SET_WORKFLOW_ATTACHMENTS = "Hook/setWorkflowAttachments";
export const SET_WORKFLOW_SELECTED = "Hook/setWorkflowSelected";
export const SET_WORKFLOW_TEMPLATES = "Hook/setWorkflowTemplates";
export const SET_MANAGEMENT_CONFIGURATION = "Hook/setManagementConfiguration";
export const SET_WORKFLOWS_CONFIGURATION = "Hook/setWorkflowsConfiguration";
export const SET_DOCUMENTS_CONFIGURATION = "Hook/setDocumentsConfiguration";
export const SET_SYSTEM_CONFIGURATION = "Hook/setSystemConfiguration";
export const SET_PTO_LOCATIONS_LIBRARY = "Hook/setPtoLocationsLibrary";
export const SET_PTO_SETTINGS = "Hook/setPtoSettings";
export const SET_FINANCIALS_CONFIGURATION = "Hook/setFinancialsConfiguration";
export const SET_PAYROLL_PERIOD = "Hook/setPayrollPeriod";
export const SET_PREFERENCES = "Hook/setPreferences";

export const SET_USERS_STREAM_INFO = "Hook/setUsersStreamInfo";
export const SET_USERS_STREAM_DICT = "Hook/setUsersStreamDict";
export const SET_TEMPLATES_CONFIGURATION = "Hook/setTemplatesConfiguration";
export const SET_TODOS = "Hook/setTodos";
export const SET_DOCUMENT_SECTIONS = "Hook/setDocumentSections";
export const SET_COMMENTS = "Hook/setComments";
export const SET_WORKFLOW_DOCUMENT = "Hook/setWorkflowDocument";
export const SET_DOC_LINE_ITEMS = "Hook/setDocLineItems";
export const SET_TIMEOFF_YEAR = "SET_TIMEOFF_YEAR";
export const SET_TIMEOFF_YEAR_OPTIONS = "SET_TIMEOFF_YEAR_OPTIONS";
export const SET_CALENDAR_MODAL_STATUS = "SET_CALENDAR_MODAL_STATUS";
export const SET_CONTACT_MODAL_STATUS = "SET_CONTACT_MODAL_STATUS";
export const SET_ASSET_MODAL_STATUS = "SET_ASSET_MODAL_STATUS";
export const SET_PROPERTY_MODAL_STATUS = "SET_PROPERTY_MODAL_STATUS";
export const SET_SOP_MODAL_STATUS = "SET_SOP_MODAL_STATUS";
export const SET_SUBMITTAL_MODAL_STATUS = "SET_SUBMITTAL_MODAL_STATUS";
export const SET_WORKFLOW_MODAL_STATUS = "SET_WORKFLOW_MODAL_STATUS";
export const SET_SOPS = "Hook/setSops";
export const SET_SOPS_DICT = "Hook/setSopsDict";
export const SET_TASKS = "Hooks/tasks";
export const SET_TASKS_DICT = "Hooks/tasksDict";
export const SET_RFI = "Hooks/rfi";
export const SET_RFI_DICT = "Hooks/rfiDict";
export const REMOVE_USER_EVENTS = "Hooks/removeUserEvents";
export const SET_RFI_MODAL_STATUS = " SET_RFI_MODAL_STATUS";
export const SET_CURRENT_USER_AVATAR = "SET_CURRENT_USER_AVATAR";
export const UPDATE_GLOBAL_STATE = "UPDATE_GLOBAL_STATE";
export const SET_REQUEST_MODAL_STATUS = "SET_REQUEST_MODAL_STATUS";
export const SET_PAGE_STATE = "SET_PAGE_STATE";
export const SET_CALENDAR_TIMEZONE = "SET_CALENDAR_TIMEZONE";

// Modal State
export const ADD_OPEN_MODAL = "Hooks/openModal";
export const MINIMIZE_ALL_MODALS = "Hooks/minimizeModals";
export const MINIMIZE_MODAL = "Hooks/minimizeModal";
export const MAXIMIZE_MODAL = "Hooks/maximizeModal";
export const EXPAND_MODAL = "Hooks/expandModal";
export const CONTRACT_MODAL = "Hooks/contractModal";
export const REMOVE_MODAL_INSTANCE = "Hooks/removeModalInstance";
export const REMOVE_POSITIONED_MODALS = "Hooks/removePositionedModals";
export const UPDATE_MODAL_INSTANCE = "UPDATE_MODAL_INSTANCE";
export const ADD_PREFERENCE_MODAL = "ADD_PREFERENCE_MODAL";

export const TOGGLE_POSITIONED_POPUP = "TOGGLE_POSITIONED_POPUP";
export const VIEW_MORE_POPUP = "VIEW_MORE_POPUP";
export const EDIT_RECURRENCE_POPUP = "EDIT_RECURRENCE_POPUP";
export const DELETE_RECURRENCE_POPUP = "DELETE_RECURRENCE_POPUP";
export const RECURRENCE_FORM_POPUP = "RECURRENCE_FORM_POPUP";
export const MARK_COMPLETE_POPUP = "MARK_COMPLETE_POPUP";
export const INVITE_CONTACT_POPUP = "INVITE_CONTACT_POPUP";
export const ADD_ATTRIBUTE_POPUP = "ADD_ATTRIBUTE_POPUP";
export const EXPORT_FILE_TYPE_POPUP = "EXPORT_FILE_TYPE_POPUP";
export const CONFIRMATION_POPUP = "CONFIRMATION_POPUP";
export const ADD_CATEGORY_POPUP = "ADD_CATEGORY_POPUP";
export const VIEW_DAY_FROM_YEAR_POPUP = "VIEW_DAY_FROM_YEAR_POPUP";
export const REACTIVATE_SUBSCRIPTION_POPUP = "REACTIVATE_SUBSCRIPTION_POPUP";
export const REJECT_TIMESHEET_POPUP = "REJECT_TIMESHEET_POPUP";

export const TODO_MEMBERS_DROPDOWN = "TODO_MEMBERS_DROPDOWN";
export const TODO_SELECT_MEMBER = "DISTRO_SELECT_MEMBER";
export const VIEW_EVENT_MODAL = "VIEW_EVENT_MODAL";
export const VIEW_TASK_MODAL = "VIEW_TASK_MODAL";
export const CONFIRM_MODAL = "CONFIRM_MODAL";
export const MARK_COMPLETE = "MARK_COMPLETE";
export const CREATE_TASK_MODAL = "CREATE_TASK";
export const CREATE_EVENT_MODAL = "CREATE_EVENT";
export const CREATE_SPACE_MODAL = "CREATE_SPACE";
export const CREATE_SOP_MODAL = "CREATE_SOP_MODAL";
export const EDIT_EVENT_MODAL = "EDIT_EVENT_MODAL";
export const SET_EVENTS_RELOAD_FUNC = "SET_EVENTS_RELOAD_FUNCTION";
export const PAYROLL_CSV_COLUMNS_MODAL = "PAYROLL_CVS_COLUMNS_MODAL";
export const TIMEOFF_REQUEST_MODAL = "TIMEOFF_REQUEST_MODAL";
export const FEEDBACK_MODAL = "FEEDBACK_MODAL";
export const PROPERTY_CREATE_MODAL = "PROPERTY_CREATE_MODAL";
export const PROJECT_CREATE_MODAL = "PROJECT_CREATE_MODAL";
export const CREATE_CONTACT_MODAL = "CREATE_CONTACT_MODAL";
export const VIEW_TODO_MODAL = "VIEW_TODO_MODAL";
export const CREATE_WF_COMMENT_MODAL = "CREATE_WF_COMMENT_MODAL";
export const REMOVE_USER = "REMOVE_USER";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const CONTACT_MANAGEMENT = "CONTACT_MANAGEMENT";
export const CREATE_ASSET = "CREATE_ASSET";
export const GIVE_TIMEOFF_MODAL = "GIVE_TIMEOFF_MODAL";

// Workflow active tab & step task state to navigate back to from task details view
export const WF_ACTIVE_STEP_TASK_INFO = "WF_ACTIVE_STEP_TASK_INFO";

export const REPORT_CREATE_MODAL = "REPORT_CREATE_MODAL";
export const REPORT_EDIT_MODAL = "REPORT_EDIT_MODAL";
export const REPORT_TEMPLATE_MODAL = "REPORT_TEMPLATE_MODAL";

export const SUBMITTAL_CREATE_MODAL = "SUBMITTAL_CREATE_MODAL";

export const SETTINGS_HOLIDAYS_CREATE_MODAL = "SETTINGS_HOLIDAYS_CREATE_MODAL";

export const ALERT_MODAL = "ALERT_MODAL";

// Form map types
export const EXPENSE_CREATE_MODAL = "EXPENSE_CREATE_MODAL";
/**
 * Event State Provider
 */
export const OPEN_VIEW = "Hook/openEventView";
export const CLOSE_VIEW = "Hook/closeEventView";
export const CLOSE_RECUR = "Hook/closeEventRecurrenceModal";
export const OPEN_RECUR = "Hook/openEventRecurrenceModal";
export const SET_EDITING_EVENTS = "Hook/setEditingEvents";
export const CLOSE_EDIT_MODAL = "Hook/closeEditModal";

/**
 * Task For Service Request
 */
export const SET_TASK_FOR_SERVICE_REQUEST = "SET_TASK_FOR_SERVICE_REQUEST";

/**
 * Event State Provider
 */
export const UNFORMATTED_EVENTS_ACTIONS = {
  EDIT_SINGLE: "editSingle",
  ADD_SINGLE: "addSingle",
  ADD_RECURRING: "addRecurring",
  REMOVE_SINGLE: "removeSingle",
  REMOVE_SINGLE_INSTANCE: "removeSingleInstance",
  REMOVE_FUTURE_INSTANCES: "removeFutureInstances",
  REMOVE_RECURRING: "removeRecurring",
  EDIT_SINGLE_INSTANCE: "editSingleInstance",
  EDIT_ALL_INSTANCES: "editAllInstances",
  EDIT_FUTUREINSTANCES: "editFutureInstances",
};

/**
 * Table Helpers
 */
export const SET_TABLE_FUNCTIONS = "SET_TABLE_FUNCTIONS";
/**
 * Table Helpers
 */

export const DEFAULT_ATTRIBUTES = [
  "Acreage",
  "Bathrooms",
  "Bedrooms",
  "Square Ft",
  "Brand",
  "Purchase Date",
  "Condition",
  "Family Legacy",
];

export const URL_REGEXP = new RegExp(
  "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

// sidebar state actions

export const ADMIN_PATH = "/account-admin";

export const ADMIN_ROLES_PERMISSIONS_PATH = `${ADMIN_PATH}/roles-and-permissions`;
export const ADMIN_BILLING_PATH = `${ADMIN_PATH}/billing`;
export const MEMBERS_PATH = `${ADMIN_PATH}/members`;
export const SETTINGS_PATH = `${ADMIN_PATH}/settings`;

export const SET_CALENDAR_DATA = "Hook/useCalendarData";
export const SET_JUMP_TO_DATE = "Hook/useJumpToDate";
export const SET_CALENDAR_VIEW = "Hook/useCalendarView";
export const SET_CALENDAR_WIDGET_EVENT = "Hook/useCalendarWidgetEvent";
export const SET_VIEW_MORE = "Hook/useViewMore";

// home routes
export const HOME_PATH = "/Home";
export const MAINTENANCE_TASKS_PATH = `/maintenance`;
export const REPORT_HOURS_PATH = `/timesheet`;
export const APPROVE_HOURS_PATH = `/approve_time`;
export const GET_TIMESHEET_APPROVAL_VIEW_PATH = (userId, date) =>
  `${APPROVE_HOURS_PATH}/${userId ?? ":userId"}/${date ?? ":date"}`;
export const GET_TIMESHEET_APPROVAL_EDIT_PATH = (userId, date) =>
  `${APPROVE_HOURS_PATH}/card/${userId ?? ":userId"}/${date ?? ":date"}/edit`;
export const PAYROLL_PATH = "/payroll";

export const GET_TIMESHEET_HISTORY_PATH = (userId, date, timesheetId) =>
  `${REPORT_HOURS_PATH}/timecard_history/${timesheetId ?? ":timesheetId"}`;

export const TIMESHEET_HISTORY_PATH = `timesheet/timecard_history`;

// files paths
export const FILES_OVERVIEW_PATH = `/files`;

// Time Off Routes
export const TIME_OFF_APPROVAL_PATH = `/timeoff/approval`;
export const TIME_OFF_CREATE_PATH = `/timeoff/create`;

export const TIME_OFF_HISTORY_BY_USER_PATH = (id) =>
  `/timeoff/approval/${id ?? ":id"}`;

export const MEMBERS_INVITE_PATH = `${MEMBERS_PATH}/invite`;
export const SIGNUP_PATH = "/signup";
export const HEALTH_PATH = "/health";
export const SIGNUP_CHECKOUT_PATH = `${SIGNUP_PATH}/checkout`;
export const SIGNUP_CONFIRMATION_PATH = `${SIGNUP_PATH}/confirmation`;
export const LOGIN_PATH = "/login";
export const SIGNUP_LANDING_PATH = "/landing";
export const ROLES_PERMISSIONS_CREATE_PATH = `${ADMIN_ROLES_PERMISSIONS_PATH}/create`;
export const ROLES_PERMISSIONS_VIEW_PATH = (roleId) =>
  `${ADMIN_ROLES_PERMISSIONS_PATH}/${roleId ?? ":roleId"}`;
export const ROLES_PERMISSIONS_EDIT_PATH = (roleId) =>
  `${ADMIN_ROLES_PERMISSIONS_PATH}/${roleId ?? ":roleId"}/edit`;

// document routes
export const DOCUMENTS_PATH = `/documents`;
export const CREATE_DOCUMENTS_PATH = `${DOCUMENTS_PATH}/create`;
export const GET_DOCUMENT_VIEW = () => `${DOCUMENTS_PATH}/view`;
export const GET_DOCUMENT_HISTORY_PATH = (documentId) =>
  `${GET_DOCUMENT_VIEW()}/${documentId ?? ":documentId"}/history`;
export const GET_DOCUMENT_EDIT_PATH = (documentId) =>
  `${GET_DOCUMENT_VIEW()}/${documentId ?? ":documentId"}/edit`;
export const GET_DOCUMENT_PATH = (documentId) =>
  `${GET_DOCUMENT_VIEW()}/${documentId ?? ":documentId"}/`;
export const GET_DOCUMENT_FOLDER = () => `${DOCUMENTS_PATH}/folder`;
export const GET_DOCUMENT_FOLDER_PATH = (folderName) =>
  `${GET_DOCUMENT_FOLDER()}/${folderName ?? ":folderName"}`;
export const GET_DOCUMENT_FOLDER_FILE_PATH = (folderName, documentId) =>
  `${GET_DOCUMENT_FOLDER_PATH(folderName)}/${documentId ?? ":documentId"}`;
export const GET_DOCUMENT_FOLDER_FILE_EDIT_PATH = (folderName, documentId) =>
  `${GET_DOCUMENT_FOLDER_PATH(folderName)}/${documentId ?? ":documentId"}/edit`;
export const GET_DOCUMENT_FOLDER_FILE_HISTORY_PATH = (folderName, documentId) =>
  `${GET_DOCUMENT_FOLDER_PATH(folderName)}/${
    documentId ?? ":documentId"
  }/history`;

export const maintenanceStatus = {
  COMPLETE: "done",
  CANCELLED: "cancelled",
  SCHEDULED: "scheduled",
};

export const SOP_PATH = `/SOPs`;

//

export const FINANCIALS_PATH = `/financial`;

export const INVENTORIES_PATH = `/inventories`;
/** @deprecated All routes I'm seeing currently use singular /workflow - but these are still in the code base */
export const WORKFLOWS_PATH = `/workflows`;
export const WORKFLOWS_DRAFTS_PATH = `${WORKFLOWS_PATH}/drafts`;
export const WORKFLOWS_ADD_NEW_PATH = `${WORKFLOWS_PATH}/add`;
export const WORKFLOWS_EDIT_PATH = (workflowId) =>
  `${WORKFLOWS_PATH}/${workflowId}/edit`;
export const PROJECTS_PATH = `/projects`;
export const PROJECT_CREATION_PATH = `${PROJECTS_PATH}/create`;
export const PROJECT_ADDITIONAL_DOCUMENTS_PATH = `${PROJECTS_PATH}/create/additional`;

// Tickets/Service Reports Paths
export const GET_TICKETS_VIEW_PATH = (ticketId) => `/tickets/${ticketId}`;
// Spaces Paths
export const SPACES_PATH = "/spaces";

// New Workflows Path
export const WORKFLOW_PATH = `/workflow`;
export const WORKFLOW_DETAILS_PATH = `${WORKFLOW_PATH}/:workflowId`;
export const GET_WORKFLOW_VIEW_PATH = (workflowId) =>
  `${WORKFLOW_PATH}/${workflowId ?? ":workflowId"}`;
// export const TICKET_DETAILS_PATH = `${TICKETS_PATH}/:id`;
// Contact Paths

export const MY_PROFILE = "/myprofile";
export const CONTACTS_PATH = "/contacts";
export const CONTACT_CREATION_PATH = `${CONTACTS_PATH}/create/:type`;

/**
 * @param {*} contactId - Id of contact
 * @param {*} tabIndex - Active Tab Index
 * @returns - Path To Contact View
 */
export const GET_CONTACT_PATH = (contactId, tabIndex) =>
  `${CONTACTS_PATH}/${contactId ?? ":contactId"}/${
    tabIndex || ":currentTabIndex"
  }`;
export const GET_PROFILE_PATH = (contactId, tabIndex) =>
  `${MY_PROFILE}/${contactId ?? ":contactId"}/${
    tabIndex || ":currentTabIndex"
  }`;

export const GET_MEMBER_PATH = (contactId) =>
  `${MEMBERS_PATH}/${contactId ?? ":contactId"}`;
export const GET_CONTACT_EDIT_PATH = (contactId, tabIndex) =>
  `${CONTACTS_PATH}/edit/${contactId ?? ":contactId"}/${
    tabIndex || ":currentTabIndex"
  }`;

export const ENTITIES_PATH = `/entities`;
export const ENTITIES_CREATION_PATH = `${ENTITIES_PATH}/create`;

// projects paths
export const PROJECT_TABLE_PATH = `/projects`;
export const GET_PROJECTS_PATH = (projectId) =>
  `${PROJECTS_PATH}/${projectId ?? ":projectId"}`;
export const GET_PROJECT_FINANCIALS_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/financials`;
export const GET_PROJECT_DETAILS_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/details`;
export const GET_PROJECT_SCHEDULE_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/schedule`;
export const GET_PROJECT_BUDGET_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/budget`;

export const GET_PROJECT_MEDIA_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/media`;

export const GET_PROJECT_WORKFLOWS_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/workflows`;
export const GET_PROJECT_WORKFLOW_CREATE_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/create-workflow`;
export const GET_PROJECT_WORKFLOWS_DRAFT_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/workflows/drafts`;
export const GET_PROJECT_WORKFLOW_VIEW_PATH = (projectId, workflowId) =>
  `${GET_PROJECTS_PATH(projectId)}/workflow/${workflowId ?? ":workflowId"}`;
export const GET_PROJECT_WORKFLOW_EDIT_PATH = (projectId, workflowId) =>
  `${GET_PROJECTS_PATH(projectId)}${WORKFLOWS_EDIT_PATH(workflowId)}`;

export const GET_PROJECT_ASSETS_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/assets`;
export const GET_PROJECT_ASSET_PATH = (projectId, assetId) =>
  `${GET_PROJECT_ASSETS_PATH(projectId)}/${assetId ?? ":assetId"}`;
// project documents
export const GET_PROJECT_DOCUMENTS_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/documents-and-media`;
export const GET_PROJECT_DOCUMENTS_DOC_PATH = (projectId, documentId) =>
  `${GET_PROJECT_DOCUMENTS_PATH(projectId)}/${documentId ?? ":documentId"}`;
export const GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH = (projectId, documentId) =>
  `${GET_PROJECT_DOCUMENTS_PATH(projectId)}/${
    documentId ?? ":documentId"
  }/edit`;
export const GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH = (projectId, documentId) =>
  `${GET_PROJECT_DOCUMENTS_PATH(projectId)}/${
    documentId ?? ":documentId"
  }/history`;
export const GET_PROJECT_DOCUMENTS_DOC_ADDITIONAL_DOCS_PATH = (
  projectId,
  documentId
) =>
  `${GET_PROJECT_DOCUMENTS_PATH(projectId)}/${
    documentId ?? ":documentId"
  }/additional-documents`;

export const GET_PROJECT_INVENTORY_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/inventory`;
export const GET_PROJECT_CREATE_DOCUMENT_PATH = (projectId) =>
  `${GET_PROJECT_DOCUMENTS_PATH(projectId)}/create`;

export const GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH = (projectId) =>
  `${GET_PROJECT_BUDGET_PATH(projectId)}/documents-and-media-create`;

export const GET_PROJECT_CREATE_BUDGET_PATH = (projectId) =>
  `${GET_PROJECT_BUDGET_PATH(projectId)}/create`;
export const GET_PROJECT_ASSET_FINANCIALS_PATH = (projectId, assetId) =>
  `${GET_PROJECTS_PATH(projectId)}/asset/${assetId ?? ":assetId"}/finances`;
export const GET_PROJECT_ASSET_DOCUMENTS_PATH = (projectId, assetId) =>
  `${GET_PROJECTS_PATH(projectId)}/asset/${assetId ?? ":assetId"}/documents`;

export const GET_PROJECT_ACTIVATE_PATH = (projectId) =>
  `${GET_PROJECTS_PATH(projectId)}/activate`;

// property paths
export const PROPERTY_TABLE_PATH = `/properties`;
export const GET_PROPERTY_VIEW_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}`;

export const GET_PROPERTY_DOCUMENT_CREATE_PATH = (propertyId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/documents/create`;
export const GET_PROPERTY_FINANCE_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/finances`;

export const GET_PROPERTY_MAINTENANCE_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/maintenance`;

export const GET_PROPERTY_WORKFLOWS_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/workflows`;
export const GET_PROPERTY_WORKFLOW_VIEW_PATH = (propertyId, workflowId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/workflow/${
    workflowId ?? ":workflowId"
  }`;
export const GET_PROPERTY_WORKFLOW_EDIT_PATH = (propertyId, workflowId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}${WORKFLOWS_EDIT_PATH(workflowId)}`;
export const GET_PROPERTY_WORKFLOWS_DRAFT_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/workflows/drafts`;
export const GET_PROPERTY_WORKFLOW_CREATE_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${
    propertyId ?? ":propertyId"
  }/create-workflows/create`;

// property documents
export const GET_PROPERTY_DOCUMENT_PATH = (propertyId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/documents`;
export const GET_PROPERTY_DOCUMENTS_DOC_PATH = (propertyId, documentId) =>
  `${GET_PROPERTY_DOCUMENT_PATH(propertyId)}/${documentId ?? ":documentId"}`;
export const GET_PROPERTY_DOCUMENTS_DOC_EDIT_PATH = (propertyId, documentId) =>
  `${GET_PROPERTY_DOCUMENT_PATH(propertyId)}/${
    documentId ?? ":documentId"
  }/edit`;
export const GET_PROPERTY_DOCUMENTS_DOC_HISTORY_PATH = (
  propertyId,
  documentId
) =>
  `${GET_PROPERTY_DOCUMENT_PATH(propertyId)}/${
    documentId ?? ":documentId"
  }/history`;

export const GET_PROPERTY_ASSETS_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/assets`;
export const GET_PROPERTY_ASSET_PATH = (propertyId, assetId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/asset/${assetId ?? ":assetId"}`;
export const GET_PROPERTY_ASSET_DOCUMENTS_PATH = (propertyId, assetId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/asset/${
    assetId ?? ":assetId"
  }/documents`;
export const GET_PROPERTY_ASSET_FINANCIALS_PATH = (propertyId, assetId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/asset/${
    assetId ?? ":assetId"
  }/financials`;
export const GET_PROPERTY_ASSET_MAINTENANCE_PATH = (propertyId, assetId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/asset/${
    assetId ?? ":assetId"
  }/maintenance`;
export const GET_PROJECT_ASSET_MAINTENANCE_PATH = (projectId, assetId) =>
  `${GET_PROJECTS_PATH(projectId)}/asset/${assetId ?? ":assetId"}/maintenance`;

// asset paths
export const ASSET_TABLE_PATH = `/assets`;
export const GET_ASSET_VIEW_PATH = (assetId) =>
  `${ASSET_TABLE_PATH}/${assetId ?? ":assetId"}`;
// asset documents
export const GET_ASSET_DOCUMENT_CREATE_PATH = (assetId) =>
  `${GET_ASSET_VIEW_PATH(assetId)}/document/create`;
export const GET_ASSET_DOCUMENT_PATH = (assetId) =>
  `${GET_ASSET_VIEW_PATH(assetId)}/documents`;
export const GET_ASSET_DOCUMENTS_DOC_PATH = (assetId, documentId) =>
  `${GET_ASSET_DOCUMENT_PATH(assetId)}/${documentId ?? ":documentId"}`;
export const GET_ASSET_DOCUMENTS_DOC_EDIT_PATH = (assetId, documentId) =>
  `${GET_ASSET_DOCUMENT_PATH(assetId)}/${documentId ?? ":documentId"}/edit`;
export const GET_ASSET_DOCUMENTS_DOC_HISTORY_PATH = (assetId, documentId) =>
  `${GET_ASSET_DOCUMENT_PATH(assetId)}/${documentId ?? ":documentId"}/history`;

export const GET_ASSET_FINANCES_PATH = (assetId) =>
  `${GET_ASSET_VIEW_PATH(assetId)}/finances`;
export const GET_ASSET_MAINTENANCE_PATH = (assetId) =>
  `${GET_ASSET_VIEW_PATH(assetId)}/maintenance`;

/**
 * Calendar Paths Start
 */

export const CALENDAR_PATH = `/calendar`;
export const GET_CALENDAR_EVENT_PATH = (eventId) =>
  `${CALENDAR_PATH}/${eventId ?? ":eventId"}`;
export const PROPERTY_CALENDAR_PATH = (associationId) => {
  return `${GET_PROPERTY_VIEW_PATH(associationId)}/calendar`;
};
export const PROJECT_CALENDAR_PATH = (associationId) => {
  return `${GET_PROJECTS_PATH(associationId)}/calendar`;
};

export const EXPENSE_CATEGORIES = "expenseCategories";
// Expense categories to hide remove and edit
export const expenseItemsHideRemoveEdit = ["Tolls", "Mileage"];

// expense paths
export const EXPENSES_PATH = "/expenses";
export const GET_PROJECT_EXPENSES_PATH = (projectId) =>
  `${PROJECTS_PATH}/${projectId ?? ":projectId"}/expenses`;
export const GET_PROPERTY_EXPENSES_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/expenses`;
export const GET_ASSET_EXPENSES_PATH = (assetId) =>
  `${GET_ASSET_VIEW_PATH(assetId)}/expenses`;
export const GET_PROPERTY_ASSET_EXPENSES_PATH = (propertyId, assetId) =>
  `${GET_PROPERTY_VIEW_PATH(propertyId)}/asset/${
    assetId ?? ":assetId"
  }/expenses`;
export const GET_PROJECT_ASSET_EXPENSES_PATH = (projectId, assetId) =>
  `${GET_PROJECTS_PATH(projectId)}/asset/${assetId ?? ":assetId"}/expenses`;

// report paths
export const REPORT_PATH = `/reports`;
export const REPORT_SINGLE_PATH = `${REPORT_PATH}/:reportId`;

// sop paths
export const SOP_TABLE_PATH = `/sops`;
export const SOP_ADD_NEW_PATH = `${SOP_TABLE_PATH}/add`;
export const GET_SOP_DETAILS_PATH = (sopId) =>
  `${SOP_TABLE_PATH}/${sopId ?? ":sopId"}`;

// submittals paths
export const SUBMITTALS_TABLE_PATH = `/submittals`;

export const GET_SUBMITTAL_DETAILS_PATH = (submittalId) =>
  `${SUBMITTALS_TABLE_PATH}/${submittalId ?? ":submittalId"}`;

export const GET_PROJECT_SUBMITTALS_PATH = (projectId) =>
  `${PROJECTS_PATH}/${projectId ?? ":projectId"}/submittals`;
export const GET_PROJECT_SUBMITTAL_PATH = (projectId, submittalId) =>
  `${GET_PROJECT_SUBMITTALS_PATH(projectId)}/${submittalId ?? ":submittalId"}`;

//
export const WF_SINGLE_TASK_PAGE = { from: `"wf"`, title: "Workflow" };
export const SR_SINGLE_TASK_PAGE = { from: `"sr"`, title: "Service Request" };

// task paths
export const TASK_TABLE_PATH = "/tasks";
export const TASK_SINGLE_PATH = `/tasks/:taskId`;

// rfi paths
export const RFI_PATH = "/rfi";
export const GET_PROJECT_RFI_PATH = (projectId) =>
  `${PROJECTS_PATH}/${projectId ?? ":projectId"}/rfi`;
export const GET_PROPERTY_RFI_PATH = (propertyId) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/rfi`;
export const GET_RFI_DETAILS_PATH = (rfiId) =>
  `${RFI_PATH}/${rfiId ?? ":rfiId"}`;

// // locations paths start

export const GET_SPACES_OVERVIEW_PATH = (id) =>
  `${PROJECT_TABLE_PATH}/${id ?? ":projectId"}/spaces`;

export const GET_LOCATION_VIEW_PATH = {
  property: (propertyId, locationId) =>
    `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/locations/${
      locationId ?? ":locationId"
    }`,
  project: (projectId, locationId) =>
    `${PROJECT_TABLE_PATH}/${projectId ?? ":projectId"}/locations/${
      locationId ?? ":locationId"
    }`,
};

export const GET_SPACE_VIEW_PATH = {
  property: (propertyId, spaceId) =>
    `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/space/${
      spaceId ?? ":spaceId"
    }`,
  project: (projectId, spaceId) =>
    `${PROJECT_TABLE_PATH}/${projectId ?? ":projectId"}/space/${
      spaceId ?? ":spaceId"
    }`,
};

export const GET_PROJECT_SPACE_DOCUMENT_PATH = (
  projectId,
  locationId,
  spaceId
) =>
  `${PROJECT_TABLE_PATH}/${projectId ?? ":projectId"}/locations/${
    locationId ?? ":locationId"
  }/space/${spaceId ?? ":spaceId"}/documents`;

export const GET_PROPERTY_SPACE_DOCUMENT_PATH = (
  propertyId,
  locationId,
  spaceId
) =>
  `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/locations/${
    locationId ?? ":locationId"
  }/space/${spaceId ?? ":spaceId"}/documents`;

/**
 * Location Asset specific paths
 */

export const GET_LOCATION_ASSETS_PATH = {
  property: (propertyId, locationId) =>
    `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/locations/${
      locationId ?? ":locationId"
    }/assets`,
  project: (projectId, locationId) =>
    `${PROJECT_TABLE_PATH}/${projectId ?? ":projectId"}/locations/${
      locationId ?? ":locationId"
    }/assets`,
};

export const GET_LOCATION_ASSET_VIEW_PATH = {
  property: (propertyId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.property(propertyId, locationId)}/${
      assetId ?? ":assetId"
    }`,
  project: (projectId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.project(projectId, locationId)}/${
      assetId ?? ":assetId"
    }`,
};

export const GET_LOCATION_ASSET_DOCUMENTS_PATH = {
  property: (propertyId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.property(propertyId, locationId)}/${
      assetId ?? ":assetId"
    }/documents`,
  project: (projectId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.project(projectId, locationId)}/${
      assetId ?? ":assetId"
    }/documents`,
};

export const GET_LOCATION_ASSET_EXPENSES_PATH = {
  property: (propertyId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.property(propertyId, locationId)}/${
      assetId ?? ":assetId"
    }/expenses`,
  project: (projectId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.project(projectId, locationId)}/${
      assetId ?? ":assetId"
    }/expenses`,
};

export const GET_LOCATION_ASSET_FINANCIALS_PATH = {
  property: (propertyId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.property(propertyId, locationId)}/${
      assetId ?? ":assetId"
    }/financials`,
  project: (projectId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.project(projectId, locationId)}/${
      assetId ?? ":assetId"
    }/financials`,
};

export const GET_LOCATION_ASSET_MAINTENANCE_PATH = {
  property: (propertyId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.property(propertyId, locationId)}/${
      assetId ?? ":assetId"
    }/maintenance`,
  project: (projectId, locationId, assetId) =>
    `${GET_LOCATION_ASSETS_PATH.project(projectId, locationId)}/${
      assetId ?? ":assetId"
    }/maintenance`,
};

/**
 * Location's Space Asset specific paths
 */

export const GET_LOCATION_SPACE_ASSETS_PATH = {
  property: (propertyId, locationId, spaceId) =>
    `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/locations/${
      locationId ?? ":locationId"
    }/space/${spaceId ?? ":spaceId"}/assets`,
  project: (projectId, locationId, spaceId) =>
    `${PROJECT_TABLE_PATH}/${projectId ?? ":projectId"}/locations/${
      locationId ?? ":locationId"
    }/space/${spaceId ?? ":spaceId"}/assets`,
};

export const GET_LOCATION_SPACE_ASSET_VIEW_PATH = {
  property: (propertyId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.property(
      propertyId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}`,
  project: (projectId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.project(
      projectId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}`,
};

export const GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH = {
  property: (propertyId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.property(
      propertyId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/documents`,
  project: (projectId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.project(
      projectId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/documents`,
};

export const GET_LOCATION_SPACE_ASSET_EXPENSES_PATH = {
  property: (propertyId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.property(
      propertyId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/expenses`,
  project: (projectId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.project(
      projectId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/expenses`,
};

export const GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH = {
  property: (propertyId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.property(
      propertyId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/financials`,
  project: (projectId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.project(
      projectId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/financials`,
};

export const GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH = {
  property: (propertyId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.property(
      propertyId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/maintenance`,
  project: (projectId, locationId, spaceId, assetId) =>
    `${GET_LOCATION_SPACE_ASSETS_PATH.project(
      projectId,
      locationId,
      spaceId
    )}/${assetId ?? ":assetId"}/maintenance`,
};

export const GET_LOCATION_DOCUMENT_PATH = {
  property: (propertyId, locationId) =>
    `${PROPERTY_TABLE_PATH}/${propertyId ?? ":propertyId"}/locations/${
      locationId ?? ":locationId"
    }/documents`,
  project: (projectId, locationId) =>
    `${PROJECT_TABLE_PATH}/${projectId ?? ":projectId"}/locations/${
      locationId ?? ":locationId"
    }/documents`,
};

// property locations paths end

// Tickets Paths

export const TICKETS_PATH = "/tickets";
export const TICKET_DETAILS_PATH = `${TICKETS_PATH}/:id`;

// End of Tickets Paths

// invoice paths
export const INVOICES_PATH = `/invoices`;
export const INVOICES_CREATE_PATH = `${INVOICES_PATH}/create`;

// Profile Paths

/**
 * @param {*} tabIndex - Active Tab Index
 * @returns - Path To Profile
 */
export const PROFILE_PATH = () => `/profile/`;

export const PROFILE_EDIT_PATH = "/profile/edit";
export const HELP_PATH = `/help`;
export const MARKETPLACE_PATH = `/marketplace`;
export const GET_PROFILE_EDIT_PATH = (tabIndex) =>
  `${PROFILE_EDIT_PATH}/${tabIndex ?? ":currentTabIndex"}`;

// Contact Us path
export const CONTACT_US_PATH = `/contact-us`;

// 404 page not foun path
export const PAGE_NOT_FOUND = "/not-found";

// time period options for charts
export const TIME_PERIOD_THIS_WEEK = "this week";
export const TIME_PERIOD_THIS_MONTH = "this month";
export const TIME_PERIOD_THIS_QUARTER = "this quarter";
export const TIME_PERIOD_LAST_QUARTER = "last quarter";
export const TIME_PERIOD_ALL_TIME = "all time";
export const TIME_PERIOD_CUSTOM = "custom";

// document form constants
export const RESET = "RESET";
export const SIMPLE_INPUT = "SIMPLE_INPUT";
export const NESTED_INPUT = "NESTED_INPUT";
export const LIST_ADD_ONE = "LIST_ADD_ONE";
export const LIST_ADD_MANY = "LIST_ADD_MANY";
export const LIST_REMOVE_ONE = "LIST_REMOVE_ONE";
export const UPDATE_BUDGET_LINE = "UPDATE_BUDGET_LINE";
export const UPDATE_BUDGET_STATE = "UPDATE_BUDGET_STATE";
export const REMOVE_BUDGET_LINE = "REMOVE_BUDGET_LINE";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const REMOVE_FORM_DATA = "REMOVE_FORM_DATA";
export const DOCUMENT_ID = "DOCUMENT_ID";
export const DOCUMENT_TYPE = "DOCUMENT_TYPE";
export const LINE_ITEMS = "LINE_ITEMS";
export const NUM_DAYS = "NUM_DAYS";
export const DAY_TYPE = "DAY_TYPE";
export const SCOPE_SUMMARY = "SCOPE_SUMMARY";
export const FINANCIAL_CODE = "FINANCIAL_CODE";
export const REQUEST = "REQUEST";
export const SET_FILE_NAME = "SET_FILE_NAME";
export const ADD_LINE_ITEMS = "ADD_LINE_ITEM";
export const REMOVE_LINE_ITEMS = "REMOVE_LINE_ITEMS";
export const UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM";
export const ADD_NEW_LINE_ITEMS = "ADD_NEW_LINE_ITEMS";
export const REMOVE_NEW_LINE_ITEMS = "REMOVE_NEW_LINE_ITEMS";
export const UPDATE_NEW_LINE_ITEM = "UPDATE_NEW_LINE_ITEM";
export const ADD_DOCUMENT_ATTACHMENT = "ADD_DOCUMENT_ATTACHMENT";
export const ADD_DOCUMENT_ATTACHMENTS = "ADD_DOCUMENT_ATTACHMENTS";
export const REMOVE_DOCUMENT_ATTACHMENT = "REMOVE_DOCUMENT_ATTACHMENT";
export const UPDATE_DOCUMENT_ATTACHMENTS = "UPDATE_DOCUMENT_ATTACHMENTS";
export const ADD_QUALIFICATION = "ADD_QUALIFICATION";
export const ADD_QUALIFICATIONS = "ADD_QUALIFICATIONS";
export const REMOVE_QUALIFICATION = "REMOVE_QUALIFICATION";
export const UPDATE_QUALIFICATION = "UPDATE_QUALIFICATION";
export const ADD_DEVIATION = "ADD_DEVIATION";
export const ADD_DEVIATIONS = "ADD_DEVIATIONS";
export const UPDATE_DEVIATION = "UPDATE_DEVIATION";
export const REMOVE_DEVIATION = "REMOVE_DEVIATION";
export const REASON_FOR_CHANGE = "REASON_FOR_CHANGE";

export const BID_TYPE = "BID_TYPE";
export const VENDOR = "VENDOR";
export const ROADBLOCKS = "ROADBLOCKS";
export const ADD_SUBMISSION_REQUIREMENT = "ADD_SUBMISSION_REQUIREMENT";
export const UPDATE_SUBMISSION_REQUIREMENT = "UPDATE_SUBMISSION_REQUIREMENT";
export const ADD_ADDITIONAL_REQUIREMENT = "ADD_ADDITIONAL_REQUIREMENT";
export const ADD_ADDITIONAL_REQUIREMENTS = "ADD_ADDITIONAL_REQUIREMENTS";
export const REMOVE_ADDITIONAL_REQUIREMENT = "REMOVE_ADDITIONAL_REQUIREMENT";
export const DELIVERY_DETAILS = "DELIVERY_DETAILS";
export const ADJUSTMENT_SETTINGS = "ADJUSTMENT_SETTINGS";
export const DOCUMENT_PROJECT = "DOCUMENT_PROJECT";
export const DOCUMENT_PROPERTY = "DOCUMENT_PROPERTY";
export const DOCUMENT_STATUS = "DOCUMENT_STATUS";
export const DOCUMENT_ASSOCIATION = "DOCUMENT_ASSOCIATION";
export const ADD_ADDITIONAL_DOCUMENT = "ADD_ADDITIONAL_DOCUMENT";
export const ADD_ADDITIONAL_DOCUMENTS = "ADD_ADDITIONAL_DOCUMENTS";
export const REMOVE_ADDITIONAL_DOCUMENT = "REMOVE_ADDITIONAL_DOCUMENT";
export const UPDATE_ADDITIONAL_DOCUMENTS = "UPDATE_ADDITIONAL_DOCUMENTS";

// table filters
export const TABLE_FILTER = {
  IS_OR_NOT: (rows, columnId, filterValue) => {
    const selectedType = filterValue?.type;
    const selectedValues = filterValue?.values || [];

    if (selectedValues.length) {
      if (selectedType === "is") {
        return rows?.filter((row) => {
          return selectedValues.indexOf(row.values[columnId]) !== -1;
        });
      }

      return rows?.filter((row) => {
        return selectedValues.indexOf(row.values[columnId]) === -1;
      });
    }
    return rows;
  },
  INCLUDES_EXCLUDES: (rows, columnId, filterValue) => {
    const selectedType = filterValue?.type;
    const selectedValues = filterValue?.values || [];

    if (selectedValues.length) {
      if (selectedType === "includes") {
        return rows?.filter((row) => {
          return selectedValues.some((item) => {
            const rowValues = row.original[columnId] ?? [];
            return rowValues.includes(item);
          });
        });
      }

      return rows?.filter((row) => {
        return selectedValues.every((item) => {
          const rowValues = row.original[columnId] ?? [];
          return !rowValues.includes(item);
        });
      });
    }
    return rows;
  },
  BOOL: (rows, columnId, filterValue) => {
    const selectedIsTrue = filterValue.isTrue;
    const selectedValue =
      filterValue.value === "true" || filterValue.value === true;
    if (selectedIsTrue) {
      return rows?.filter((row) => {
        return row.values[columnId] === selectedValue;
      });
    }
    return rows?.filter((row) => {
      return row.values[columnId] !== selectedValue;
    });
  },
  DATE: (rows, columnId, filterValue) => {
    const selectedFrom = filterValue?.from;
    const selectedTo = filterValue?.to;
    if (selectedFrom && selectedTo) {
      return rows?.filter((row) => {
        return moment(row.values[columnId]).isBetween(
          moment(selectedFrom),
          moment(selectedTo),
          "days",
          "[]"
        );
      });
    }
    if (selectedFrom) {
      return rows?.filter((row) => {
        return moment(row.values[columnId]).isSameOrAfter(moment(selectedFrom));
      });
    }
    if (selectedTo) {
      return rows?.filter((row) => {
        return moment(row.values[columnId]).isSameOrBefore(moment(selectedTo));
      });
    }
    return rows;
  },
};

// new workflows
// export const DELETE_SUBMITTAL = "Hook/delete_submittal";
// export const NEW_SUBMITTAL = "Hook/new_submittal";
// export const PATCH_SUBMITTAL = "Hook/patch_submittal";
export const SET_WORKFLOW = "Hook/set_workflows";
export const SET_WORKFLOW_DICT = "Hook/set_workflow_dict";
export const SET_WORKFLOW_DD = "Hook/set_workflow_dd";

// submittals
export const DELETE_SUBMITTAL = "Hook/delete_submittal";
export const NEW_SUBMITTAL = "Hook/new_submittal";
export const PATCH_SUBMITTAL = "Hook/patch_submittal";
export const SET_SUBMITTALS = "Hook/set_submittals";
export const SET_SUBMITTAL_DICT = "Hook/set_submittal_dict";
export const SET_SUBMITTAL_DD = "Hook/set_submittal_dd";
export const INITIAL_REQUEST_TEMPLATE = {
  requestWorkflow: [
    {
      steps: [
        {
          duration: {
            projected: 10,
          },
          description: "Step 1",
          position: 1,
          users: [],
        },
        {
          duration: {
            projected: 10,
          },
          description: "Step 2",
          position: 2,
          users: [],
        },
        {
          duration: {
            projected: 10,
          },
          description: "Step 3",
          position: 3,
          users: [],
        },
        {
          duration: {
            projected: 10,
          },
          description: "Step 4",
          position: 4,
          users: [],
        },
      ],
    },
  ],
  isTemplate: true,
  isDefaultTemplate: false,
  customName: "",
};

// document types
export const PURCHASE_AUTHORIZATION = "purchaseAuthorization";
export const PURCHASE_ORDER = "purchaseOrder";
export const CHANGE_ORDER = "changeOrder";
export const REQUEST_FOR_PROPOSAL = "requestForProposal";
export const REQUEST_FOR_INFORMATION = "requestForInformation";
export const CONTINGENCY_AUTHORIZATION = "contingencyAuthorization";

export const INVOICE = "invoice";

export const VENDOR_INVOICE = "vendorInvoice";

// TimeSheet Status
export const APPROVED_TS = "approved";
export const REJECTED_TS = "rejected";

export const CUMULATIVE_VIEW = "Cumulative";
export const EMPLOYEE_VIEW = "Employee";
export const PROJECT_VIEW = "Project";
export const APPROVED_WITH_CHANGES_TS = "approved-with-changes";
export const TS_HISTORY_MESSAGE = (actionee, period) => ({
  "payroll-approved": `Payroll Approved by ${actionee} ${period}`,
  "payroll-rejected": `Payroll Rejected by ${actionee} ${period}`,
  rejected: `Rejected by HR ${actionee} ${period}`,
  approved: `Approved by HR ${actionee} ${period}`,
  submitted: `Time Card Submitted by ${actionee} ${period}`,
  edit: `Time Card Edited by ${actionee} ${period}`,
});

// Submittal status

export const SUBMITTAL_COMPLETED_RESOLVED = "completed-resolved";
export const SUBMITTAL_COMPLETED_OPEN = "completed-open";
export const SUBMITTAL_DRAFT = "draft";
export const SUBMITTAL_VOID = "void";

export const SUBMITTAL_IN_PROGRESS = "in-progress";

export const UNRESOLVED_SUBMITTAL_STEP = "unresolved";
export const checkRequestStatus = (status) =>
  ![
    SUBMITTAL_COMPLETED_RESOLVED,
    SUBMITTAL_COMPLETED_OPEN,
    SUBMITTAL_VOID,
  ].includes(status);

export const SUBMITTAL_STATUS_TYPES = {
  DRAFT: SUBMITTAL_DRAFT,
  IN_PROGRESS: SUBMITTAL_IN_PROGRESS,
  COMPLETED_RESOLVED: SUBMITTAL_COMPLETED_RESOLVED,
  COMPLETED_OPEN: SUBMITTAL_COMPLETED_OPEN,
  VOID: SUBMITTAL_VOID,
};

export const SUBMITTAL_FORMATED_STATUS = {
  VOID: "void",
  DRAFT: "draft",
  IN_PROGRESS: "in progress",
  COMPLETE_OPEN: "complete - open",
  COMPLETE_RESOLVED: "complete - resolved",
  PENDING_COMPLETION: "pending completion",
};

// Workflow status
export const RESOLVED_WF = "resolved";
export const ADVANCED_WF_STEP = "advanced";
export const RESUBMISSION_WF_STEP = "resubmissionRequested";
export const UNRESOLVED_WF_STEP = "unresolved";

export const WF_COMPLETED_RESOLVED = "completed-resolved";
export const WF_COMPLETED_OPEN = "completed-open";
export const WF_DRAFT = "draft";
export const WF_VOID = "void";

export const WF_IN_PROGRESS = "in-progress";

export const checkWFRequestStatus = (status) =>
  ![WF_COMPLETED_RESOLVED, WF_COMPLETED_OPEN, WF_VOID].includes(status);

export const WF_STATUS_TYPES = {
  DRAFT: WF_DRAFT,
  IN_PROGRESS: WF_IN_PROGRESS,
  COMPLETED_RESOLVED: WF_COMPLETED_RESOLVED,
  COMPLETED_OPEN: WF_COMPLETED_OPEN,
  VOID: WF_VOID,
};

// Workflow step action status
export const WF_STEP_ACTION_STATUS = {
  COMPLETED: "Completed",
  PENDING: "Action Pending",
  UPCOMING: "Upcoming",
  WAITING: "waiting",
  VOID: "void",
};

export const REQUEST_WORKFLOW_ROLES = [
  { label: "Submitter", value: "submitter" },
  { label: "Reviewer", value: "reviewer" },
  { label: "Approver", value: "approver" },
];

// Workflow Validation
export const WFStepSchema = yup.object().shape({
  description: yup.string().required(),
  dueDate: yup.mixed().required(),
  members: yup
    .array()
    .of(
      yup.object().shape({
        reference: yup.string().required(),
      })
    )
    .min(1)
    .required(),
});

export const DOCUMENT_STATUS_PILL_INFO = {
  open: {
    name: "Open",
    css: "bg-accessibleBlue text-white font-semibold",
  },
  approved: {
    name: "Pending Merge", // "Approved",
    css: "bg-navyBlue text-white font-semibold",
  },
  pendingMerge: {
    name: "Pending Merge",
    css: "bg-navyBlue text-white font-semibold",
  },
  closed: {
    name: "Merged", // "Closed",
    css: "bg-gray-450 text-white font-semibold",
  },
  merged: {
    name: "Merged",
    css: "bg-gray-450 text-white font-semibold",
  },
  requestResubmission: {
    name: "Resubmission Requsted",
    css: "bg-accessibleBlue text-white font-semibold",
  },
  locked: {
    name: "Submitted", // "Locked",
    css: "bg-white text-primaryGreen border border-primaryGreen font-semibold",
    showLock: true,
  },
  submitted: {
    name: "Submitted",
    css: "bg-white text-primaryGreen border border-primaryGreen font-semibold",
    showLock: true,
  },
  pendingClientAcceptance: {
    name: "Pending Client Acceptance",
    css: "bg-accessibleBlue text-white font-semibold",
  },
  pendingVendorAcceptance: {
    name: "Pending Vendor Acceptance",
    css: "bg-accessibleBlue text-white font-semibold",
  },
  draft: {
    name: "Draft",
    css: "bg-gray-100 text-gray-450 border border-gray-175 font-semibold",
  },
};

// status
export const STATUS_PILL_INFO = {
  inProgress: {
    name: "In Progress",
    css: "bg-accessibleBlue text-white font-semibold",
  },
  onTrack: {
    name: "On Track",
    css: "bg-accessibleBlue text-white font-semibold",
  },
  dueSoon: {
    name: "Due Soon",
    css: "bg-brandYellow text-gray-500",
  },
  overdue: { name: "Overdue", css: "bg-brandRed text-white font-semibold" },
  resubmissionRequested: {
    name: "Resubmission Requsted",
    css: "bg-accessibleRed text-gray-500 font-semibold",
  },
  unresolved: {
    name: "Unresolved",
    css: "bg-accessiblePurple text-white font-semibold",
  },
  resolved: {
    name: "Completed",
    css: "bg-brandGreen text-white font-semibold",
  },
  approved: {
    name: "Approved",
    css: "bg-brandGreen text-white font-semibold",
  },
  draft: {
    name: "Draft",
    css: "bg-gray-400 text-white font-semibold",
  },
  pending: {
    name: "Pending Completion",
    css: "bg-accessibleBlue text-white font-semibold",
  },
};

// document types for dropdown select
export const docTypeOptionsMap = {
  changeOrder: { label: "Change Order (CO)", value: "changeOrder" },
  contingencyAuthorization: {
    label: "Contingency Authorization (CA)",
    value: "contingencyAuthorization",
  },
  dailyLog: { label: "Daily Log", value: "dailyLog" },
  drawing: { label: "Drawings", value: "drawing" },
  employeeDocument: { label: "Employee Document", value: "employeeDocument" },
  invoice: { label: "Invoice", value: "invoice" },
  meetingMinutes: { label: "Meeting Minutes", value: "meetingMinutes" },
  purchaseAuthorization: {
    label: "Purchase Authorization (PA)",
    value: "purchaseAuthorization",
  },
  purchaseOrder: { label: "Purchase Order (PO)", value: "purchaseOrder" },
  requestForInformation: {
    label: "Request For Information (RFI)",
    value: "requestForInformation",
  },
  requestForProposal: {
    label: "Request For Proposal (RFP)",
    value: "requestForProposal",
  },
  submittal: { label: "Submittal", value: "submittal" },
};

export const financialDocumentsType = {
  changeOrder: "Change Order",
  contingencyAuthorization: "Contingency Authorization",
  purchaseAuthorization: "Purchase Authorization",
  purchaseOrder: "Purchase Order",
  requestForInformation: "Request for Information",
  requestForProposal: "Request for Proposal",
  invoice: "Invoice",
  vendorInvoice: "Vendor Invoice",
};

export const docTypeOptions = [
  { label: "Change Order (CO)", value: "changeOrder" },
  {
    label: "Contingency Authorization (CA)",
    value: "contingencyAuthorization",
  },
  { label: "Daily Log", value: "dailyLog" },
  { label: "Drawings", value: "drawing" },
  { label: "Employee Document", value: "employeeDocument" },
  { label: "Invoice", value: "invoice" },
  { label: "Meeting Minutes", value: "meetingMinutes" },
  { label: "Purchase Authorization (PA)", value: "purchaseAuthorization" },
  { label: "Purchase Order (PO)", value: "purchaseOrder" },
  { label: "Request For Information (RFI)", value: "requestForInformation" },
  { label: "Request For Proposal (RFP)", value: "requestForProposal" },
  { label: "Submittal", value: "submittal" },
];

export const financialDocumentsTypeAcronyms = {
  changeOrder: "CO",
  contingencyAuthorization: "CA",
  purchaseAuthorization: "PA",
  purchaseOrder: "PO",
  requestForInformation: "RFI",
  requestForProposal: "RFP",
  invoice: "Invoice",
  vendorInvoice: "Vendor Invoice",
};

// table column edit tools
export const SHOW_COLUMNS_SETTING_RIGHT = true;

// table filter options
export const INCLUDES_EXCLUDES_OPTIONS = [
  {
    label: "includes",
    value: "includes",
  },
  {
    label: "excludes",
    value: "excludes",
  },
];

// table filter types
export const FILTER_TYPES = {
  boolean: "boolean",
  isOrNot: "isOrNot",
  date: "date",
  includesExcludes: "includesExcludes",
  tags: "tags",
  userRefs: "userRefs",
};

/**
 * TimeSheet Constants
 */
export const RESET_TIMESHEET_STATE = "Hooks/ResetTimesheetState";
export const SET_TIMECARDS_USER_DETAIL_VIEW =
  "Hooks/SetTimeCardsUserDetailView";
export const SET_TIMECARD_USER_DATE = "SET_TIMECARD_USER_DATE";
export const RESET_TIMECARDS_VIEW = "Hooks/ResetTimecardsView";
export const SET_APPROVAL_USER_DETAIL_VIEW = "Hooks/SetApprovalUserDetailView";
export const SET_APPROVAL_DATE = "Hooks/SetApprovalDate";
export const SET_APPROVAL_USER_CARD_VIEW = "Hooks/SetApprovalUserCardView";
export const RESET_APPROVAL_VIEW = "Hooks/ResetApprovalView";
export const SET_PAYROLL_DETAIL_VIEW = "Hooks/SetPayrollDetailView";
export const RESET_PAYROLL_VIEW = "Hooks/ResetPayrollView";

export const SET_PTO_USER_DETAIL_VIEW = "Hooks/SetPtoUser";
export const RESET_PTO_VIEW = "Hooks/ResetPtoView";
export const SET_ACTIVE_TAB = "Hooks/SetActiveTab";

export const WEEKLY_TIME_LIMIT = 40.0;
export const DAILY_TIME_LIMIT = 8.0;

export const defaultCsvColumns = [
  "Name",
  "Employee ID",
  "Project",
  "Title",
  "Category",
  "Type",
  "State Of Employment",
  "Payroll Period",
  "Approver",
  "Dates",
  "Rate Per Hours",
  "Rate Over 40 Hours",
  "Premium Rate",
  "Regular Hours",
  "OT Hours",
  "Time Off",
  "Total Hours",
  "Total Hours Non-Billable",
  "Total Hours Billable",
];

/**
 * TimeSheet Constants
 */

// project dashboard widgets
export const DASHBOARD_WIDGET_WEATHER = "Weather";
export const DASHBOARD_WIDGET_CALENDAR = "Calendar";

// home widgets
export const HOME_WIDGET_CALENDAR = "Calendar";

export const HOME_WIDGET_MESSAGES = "Messages";

export const SETTINGS_HOME_DASHBOARD_ORDER = "homeDashboardOrder";

// drag and drop item types
export const DND_WIDGET = "widget";

// settings actions
export const SETTINGS_PROJECT_DASHBOARD_ORDER = "projectDashboardOrder";
export const SETTINGS_PAYROLL_CSV_COLUMNS = "payrollCsvColumns";

// financials paths
export const FINANCIALS_ACTUAL_PATH = `${FINANCIALS_PATH}/actual`;

// create company constants
export const memberRoles = [
  "Accountant",
  "Assessor",
  "Architect",
  "Engineer",
  "Estimator",
  "Executive Sponsor",
  "Construction Manager",
  "Manager",
  "Project Manager",
  "Senior Project Manager",
];

// Message Filters

// create project
export const PROJECT_RESET = "projectReset";
export const PROJECT_NAME = "projectName";
export const PROJECT_TYPE = "projectType";
export const PROJECT_PROPERTY = "projectProperty";
export const PROJECT_TIMEZONE = "projectTimezone";
export const PROJECT_DURATION = "projectDuration";
export const PROJECT_DURATION_TYPE = "projectDurationType";
export const PROJECT_UPDATE_DURATION = "projectUpdateDuration";
export const PROJECT_DESCRIPTION = "projectDescription";
export const PROJECT_GOALS = "projectGoals";
export const PROJECT_CHALLENGES = "projectChallenges";
export const PROJECT_CONTRACT_DATE = "projectContractDate";
export const PROJECT_START_DATE = "projectStartDate";
export const PROJECT_END_DATE = "projectEndDate";
export const PROJECT_AMOUNT = "projectAmount";
export const PROJECT_MAILING_SAME_AS_PHYSICAL = "projectMailingSameAsPhysical";
export const PROJECT_ADDRESS = "projectAddress";
export const PROJECT_BUDGET_TYPE = "projectBudgetType";
export const PROJECT_ADD_RETAINAGE = "projectAddRetainage";
export const PROJECT_REMOVE_RETAINAGE = "projectRemoveRetainage";
export const PROJECT_UPDATE_RETAINAGE = "projectUpdateRetainage";

export const PROJECT_VENDOR = "projectVendor";
export const PROJECT_ADD_INSURANCE = "projectAddInsurance";
export const PROJECT_REMOVE_INSURANCE = "projectRemoveInsurance";
export const PROJECT_INSURANCE_TYPE = "projectInsuranceType";
export const PROJECT_PREMIUM = "projectPremium";
export const PROJECT_FINANCES_MASTER_LIST = "projectFinancesMasterList";
export const PROJECT_FEE_TYPE = "projectFeeType";
export const PROJECT_FEE = "projectFee";
export const PROJECT_WARRANTY = "projectWarranty";
export const PROJECT_DAY_TYPE = "projectDayType";
export const PROJECT_DELAY = "projectDelay";
export const PROJECT_UNFORSEEN_CONDITIONS = "projectUnforseenConditions";
export const PROJECT_DISCREPANCIES = "projectDiscrepancies";
export const PROJECT_ALLOWANCE = "projectAllowance";
export const PROJECT_ADD_QUALIFICATION = "projectAddQualification";
export const PROJECT_REMOVE_QUALIFICATION = "projectRemoveQualification";
export const PROJECT_CONTRACTS_MASTER_LIST = "projectContractsMasterList";
export const PROJECT_SELECT_DATES = "projectSelectDates";
export const PROJECT_HOURS_OF_OPERATION = "projectHoursOfOperation";
export const PROJECT_ADD_MEMBER = "projectAddMember";
export const PROJECT_REMOVE_MEMBER = "projectRemoveMember";

export const PROJECT_UPDATE_RATE = "projectUpdateRate";
export const PROJECT_STATUS = "projectStatus";

// task event recurrence
export const SINGLE_EVENT_INSTANCE = "single";
export const ALL_EVENT_INSTANCES = "all";
export const FUTURE_EVENT_INSTANCES = "future";

export const DAILY_RECURRENCE = "Daily";
export const WEEKLY_RECURRENCE = "Weekly";
export const MONTHLY_RECURRENCE = "Monthly";
export const YEARLY_RECURRENCE = "Yearly";

// minimum recurrence and occurrence values
export const MINIMUM_RECURRENCE_VALUE = 1;
export const MINIMUM_OCCURRENCE_VALUE = 1;

export const RADIO_ON = "On";
export const RADIO_AFTER = "After";
export const RADIO_NEVER = "Never";

// day names
export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dayAbbreviations = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
];

export const hours = [
  "",
  "1 am",
  "2 am",
  "3 am",
  "4 am",
  "5 am",
  "6 am",
  "7 am",
  "8 am",
  "9 am",
  "10 am",
  "11 am",
  "12 pm",
  "1 pm",
  "2 pm",
  "3 pm",
  "4 pm",
  "5 pm",
  "6 pm",
  "7 pm",
  "8 pm",
  "9 pm",
  "10 pm",
  "11 pm",
  "",
];

// notification constants

export const notificationCheckboxOptions = [
  "All",
  "None",
  "Read",
  "Unread",
  "Flagged",
];

// message constants
export const messageFilterOptions = {
  pinned: "Pinned",
  hasReplies: "Has Replies",
  hasAttachments: "Has Attachments",
  today: "Today",
  yesterday: "Yesterday",
  days7: "Past 7 Days",
};
// doc filters
export const documentFilterOptions = {
  pinned: "Pinned",
  flagged: "Flagged",
  hasReplies: "Has Replies",
  hasAttachments: "Has Attachments",
  today: "Today",
  yesterday: "Yesterday",
  days7: "Past 7 Days",
};
export const filterDayOffsets = { today: 0, yesterday: 1, days7: 7 };

// stripe plans
export const SUBSCRIPTION_PLANS = {
  enterprise: "Enterprise",
  professional: "Professional",
  starter: "Starter",
};

// PTO types settings
export const PTO_TYPES = [
  "unlimited",
  "bereavement",
  "juryDuty",
  "parentalLeave",
  "personalLeave",
  "sick",
  "vacation",
  "unpaid",
  "floatingHoliday",
];

export const PTO_CATEGORY = {
  location: "location",
  general: "general",
};

// PTO Display Types
export const PTO_TYPE_DISPLAY = {
  vacation: "Vacation",
  sick: "Sick",
  personalLeave: "Personal Leave",
  bereavement: "Bereavement",
  juryDuty: "Jury Duty",
  parentalLeave: "Parental Leave",
  unpaid: "Unpaid",
  floatingHoliday: "Floating Holiday",
};

export const PTO_STATUS_TYPES = {
  pending: "pending",
  vacation: "vacation",
  sick: "sick",
  personalLeave: "personalLeave",
  approved: "approved",
};

// PTO time off types settings
export const TIME_OFF_TYPES = [
  {
    label: "Unlimited",
    value: "unlimited",
  },
  {
    label: "Bereavement",
    value: "bereavement",
  },
  {
    label: "Jury Duty",
    value: "juryDuty",
  },
  {
    label: "Parental Leave",
    value: "parentalLeave",
  },
  {
    label: "Personal",
    value: "personalLeave",
  },
  {
    label: "Sick",
    value: "sick",
  },
  {
    label: "Vacation",
    value: "vacation",
  },
  {
    label: "Unpaid",
    value: "unpaid",
  },
  {
    label: "Floating Holiday",
    value: "floatingHoliday",
  },
];

// PTO accrual types settings
export const ACCRUAL_TYPES = [
  {
    label: "Accrual",
    value: "accrual",
  },
  {
    label: "Non-accrual",
    value: "non-accrual",
  },
];

export const MAX_ACCRUAL_OPTIONS = [
  {
    label: "100",
    value: "100.00",
  },
  {
    label: "150",
    value: "150.00",
  },
  {
    label: "200",
    value: "200.00",
  },
  {
    label: "250",
    value: "250.00",
  },
  {
    label: "300",
    value: "300.00",
  },
];

// COA code types settings
export const COA_CODE_TYPES = [
  {
    label: "Expense",
    value: "expense",
  },
  {
    label: "Revenue",
    value: "revenue",
  },
];

export const COA_ACCOUNT_TYPES = [
  {
    label: "Fixed Assets or Other/Current Assets",
    value: "fixedAssets",
    type: "unspecified",
  },
  {
    label: "Liabilities or Other/Current Liabilities",
    value: "liabilities",
    type: "unspecified",
  },
  {
    label: "Equity",
    value: "equity",
    type: "unspecified",
  },
  {
    label: "Income or Revenue",
    value: "incomeOrRevenue",
    type: "revenue",
  },
  {
    label: "Job Costs/Costs of Goods Sold",
    value: "jobCosts",
    type: "expense",
  },
  {
    label: "Overhead Costs or Expenses",
    value: "overheadCostsOrExpenses",
    type: "expense",
  },
  {
    label: "Other Income",
    value: "otherIncome",
    type: "unspecified",
  },
  {
    label: "Other Expense",
    value: "otherExpense",
    type: "unspecified",
  },
];

export const PAYROLL_PERIODS = [
  {
    label: "Every Week",
    value: "everyweek",
  },
  /**
   * Hiding as broken (ES-8627)
   */
  // {
  //   label: "Every Other Week",
  //   value: "everyotherweek",
  // },
  /**
   * TBD [Future Development]
   */
  // {
  //   label: "Monthly",
  //   value: "monthly",
  // },
  // {
  //   label: "1st and 15th Day of the Month",
  //   value: "firstandfifteenth",
  // },
  // {
  //   label: "15th and Last Day of the Month",
  //   value: "fifteenthandlast",
  // },
];

export const DAYS_OF_WEEK = [
  {
    label: "Sunday",
    value: "sunday",
    endOfWeekDay: "saturday",
  },
  {
    label: "Monday",
    value: "monday",
    endOfWeekDay: "sunday",
  },
  {
    label: "Tuesday",
    value: "tuesday",
    endOfWeekDay: "monday",
  },
  {
    label: "Wednesday",
    value: "wednesday",
    endOfWeekDay: "tuesday",
  },
  {
    label: "Thursday",
    value: "thursday",
    endOfWeekDay: "wednesday",
  },
  {
    label: "Friday",
    value: "friday",
    endOfWeekDay: "thursday",
  },
  {
    label: "Saturday",
    value: "saturday",
    endOfWeekDay: "friday",
  },
];

// project constants

// Project Duration Types
export const durationTypeOptions = [
  { label: "Day(s)", value: "calendarDay" },
  { label: "Month(s)", value: "month" },
  { label: "Year(s)", value: "year" },
  { label: "Week(s)", value: "week" },
];

export const timeUnits = [
  { label: "Min(s)", value: "minute" },
  { label: "Hr(s)", value: "hour" },
  { label: "Day(s)", value: "calendarDay" },
  { label: "Week(s)", value: "week" },
  { label: "Month(s)", value: "month" },
  { label: "Year(s)", value: "year" },
];

export const MEMBER_SALARY_OPTIONS = [
  {
    label: "Annual Salary",
    value: "annual",
  },
  {
    label: "Hourly Pay",
    value: "hourly",
  },
];

export const EMPLOYEE_TYPES = [
  {
    label: "Exempt",
    value: "exempt",
  },
  {
    label: "Non-exempt",
    value: "non-exempt",
  },
];

export const PROPERTY_TYPES = [
  {
    label: "Commercial",
    value: "commercial",
  },
  {
    label: "Residential",
    value: "residential",
  },
];

export const GENDER_TYPES = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Unspecified",
    value: "unspecified",
  },
  {
    label: "Nonbinary",
    value: "nonbinary",
  },
];

// asset categories URI

// default hours of operation
export const defaultHoursOfOperation = {
  from: { label: "9:00 AM", value: "9:00" },
  to: { label: "5:00 PM", value: "17:00" },
};

// time dropdown options
export const TimeOptions = Array.from({ length: 24 }, (_, i) => i).reduce(
  (list, hour) => {
    const firstLabel = moment({ hour, minute: 0 }).format("hh:mm A");
    const firstValue = moment({ hour, minute: 0 }).format("HH:mm");
    const secondLabel = moment({ hour, minute: 30 }).format("hh:mm A");
    const secondValue = moment({ hour, minute: 30 }).format("HH:mm");

    list.push({ label: firstLabel, value: firstValue });
    list.push({ label: secondLabel, value: secondValue });

    return list;
  },
  []
);

// create asset document suggestions
export const createAssetTooltipTitle =
  "Assets like this typically have these types of documents";
export const createAssetDocuments = [
  {
    label: "Affidavit of Title or Seller's Affidavit",
    content:
      "Seller confirming ownership of the property and describing any known title defects such as leases, liens, etc.",
  },
  {
    label: "Bill of Sale",
    content:
      "Personal property being sold along with a house (if any), such as appliances and furniture, from seller to buyer.",
  },
  {
    label: "Certificate of Authenticity",
    content:
      "Official document that proves your work is genuine and authentic.",
  },
  {
    label: "Certificate of Ownership",
    content:
      "This document transfers the property from the seller to the buyer.",
  },
  {
    label: "Insurance Certificate",
    content: "Provide proof of active insurance at or just before closing.",
  },
  {
    label: "Owner Manual",
    content:
      "An instructional book or booklet that is supplied with most assets.",
  },
  {
    label: "Warranty",
    content:
      "A guarantee from a manufacturer or seller that defective products will be repaired or replaced.",
  },
];

// Asset Financials Appraisal ToolTip Title

// create project document suggestions

// create property document suggestions
export const createPropertyTooltipTitle =
  "Properties like this typically have these types of documents";
export const createPropertyDocuments = [
  {
    label: "Deed",
    content: "This document transfers the property from the seller to buyer.",
  },
  {
    label: "Affidavit of Title or Seller's Affidavit",
    content:
      "Seller confirming ownership of the property and describing any known title defects such as leases, liens, etc.",
  },
  {
    label: "Transfer Tax Declarations",
    content:
      "Real property transfer taxes require buyer and seller to sign declarations disclosing purchase price calculated tax.",
  },
  {
    label: "Insurance Certificate",
    content: "Provide proof of active insurance at or just before closing.",
  },
  {
    label: "Certificate of Occupancy",
    content:
      "Verifies that the property meets local building codes and is habitable.",
  },
  {
    label: "Note from Lender",
    content:
      "Evidence of your debt to the lender, a description of loan terms, and a means for the lender to transfer/collect debt.",
  },
  {
    label: "Deed of Trust or Mortgage",
    content:
      "This is your agreement to put up the property as collateral for the loan.",
  },
  {
    label: "Loan Application",
    content: "Completed a loan application form when you apply for the loan.",
  },
  {
    label: "Loan Estimate and Closing Disclosure",
    content:
      "These documents are designed to help you better understand the mortgage loan transaction.",
  },
  {
    label: "Bill of Sale",
    content:
      "Personal property being sold along with a house (is any), such as appliances and furniture, from seller to buyer.",
  },
];
export const assetMediaTooltipTitle = "Click behaviors";
export const assetMediaTooltip = [
  {
    label: "Clicking",
    content:
      "Clicking any thumbnail selects it. If another thumbnail is selected (or multiple other thumbnails are selected), they are deselected. So, only one thumbnail at a time can be selected by clicking.",
  },
  {
    label: "Control-clicking (or Command-clicking on a Mac)",
    content:
      "Control-clicking (or Command-clicking on a Mac) a thumbnail toggles its selected status. This does not affect other thumbnails that may be selected. So, by control/command-clicking, users can select multiple thumbnails.",
  },
  {
    label: "Shift-clicking",
    content:
      "Shift-clicking a thumbnail selects all the thumbnails between the shift-clicked thumbnail and any previously clicked thumbnail. So, by clicking one thumbnail, and then shift-clicking another, users can select a range of thumbnails.",
  },
  {
    label: "Double-clicking",
    content:
      "Double-clicking a thumbnail opens the media viewer, with the clicked thumbnail as the main image in the viewer.",
  },
  {
    label: "",
    content:
      "While one or more thumbnails is selected, clicking anything other than a thumbnail deselects all thumbnails. So, by clicking outside the media widget, users can quickly deselect all thumbnails.",
  },
  {
    label: "Button behaviors",
    content:
      "Download, Delete, and Deselect all are active if at least one thumbnail is selected; otherwise, they are disabled.",
  },
  {
    label: "",
    content:
      "Set as primary is active if exactly one thumbnail is selected; otherwise, it is disabled.",
  },
];

// Workflows Distro Tooltip

// Budget Distro Tooltip
export const budgetwDistroTooltip = [
  {
    label: "Time and material",
    content:
      "Time and material projects are an organic budget development as work is completed in real time. Costs are comprised of timecards, expenses, and vendor POs.",
  },
  {
    label: "Fixed Firm",
    content:
      "Fixed Firm projects are a predetermined budget developed prior to work commencing.",
  },
  {
    label: "Cost Plus",
    content:
      "Cost Plus projects are typically a partially predetermined budget developed prior to work commencing.",
  },
  {
    label: "Cost Plus with Lock GMP",
    content:
      "Cost Plus with Lock GMP sets a Guaranteed Max Price essentially moving form a cost plus to a more fixed firm experience.",
  },
];

export const settingsList = [
  {
    id: "assetCategories",
    title: "Asset Categories",
    type: "Management",
    description: "Manage asset categories available across EstateSpace",
  },
  {
    id: "attributes",
    title: "Attributes",
    type: "Management",
    description: "Manage attributes available across EstateSpace",
  },
  {
    id: "benefits",
    title: "Benefits",
    type: "System",
    description:
      "Manage the benefits offered to include health care, dental, vision, life insurance and disability insurance for employees",
  },
  {
    id: "lily",
    title: "Lily",
    type: "Lily",
    description: "Manage Lily's permission and privacy settings",
  },
  {
    id: "budgetsUnitsOfMeasure",
    title: "Budgets Units of Measure",
    type: "System",
    description: "Manage the available units of measure for budget line items",
  },
  {
    id: "chartOfAccounts",
    title: "Chart of Accounts",
    type: "Financial",
    description:
      "Manage the master list of account codes for mapping of project finances",
  },
  {
    id: "contactDisciplines",
    title: "Contact Disciplines",
    type: "Management",
    description:
      "Manage disciplines available across contacts or companies in EstateSpace",
  },
  {
    id: "csiCodesAndMapping",
    title: "CSI Codes & Mapping",
    type: "Financial",
    description:
      "Manage the master list of CSI codes for budgeting on projects in EstateSpace",
  },
  {
    id: "divisionCodes",
    title: "Division Codes",
    type: "Financial",
    description: "Manage division codes for your chart of accounts",
  },
  {
    id: "expenseCategories",
    title: "Expense Categories",
    type: "Financial",
    description:
      "Manage the master list of expense categories across EstateSpace",
  },
  {
    id: "holidays",
    title: "Holidays",
    type: "System",
    description: "Select the holidays and add custom holidays in EstateSpace",
  },
  {
    id: "hoursOfOperation",
    title: "Hours of Operation",
    type: "System",
    description:
      "Manage the default hours of operation for new projects or properties in EstateSpace",
  },
  {
    id: "levelTypes",
    title: "Level Types",
    type: "Management",
    description: "Manage the levels available across EstateSpace",
  },
  {
    id: "paidTimeOff",
    title: "Paid Time Off",
    type: "Financial",
    description: "Manage the PTO types available in timesheets for employees",
  },
  {
    id: "payrollPeriod",
    title: "Payroll Period",
    type: "Financial",
    description:
      "Set the payroll period that will apply for all timesheets in EstateSpace. This can not be changed once timesheets are active",
  },
  {
    id: "preferencesLibrary",
    title: "Preferences Library",
    type: "Management",
    description:
      "Manage the list of favorites, dislikes and special conditions that available for contact management",
  },
  {
    id: "projectTypes",
    title: "Project Types",
    type: "Management",
    description:
      "Select the project types and add custom types for projects in EstateSpace",
  },
  {
    id: "projectInspectionTypes",
    title: "Project Inspection Types",
    type: "Management",
    description: "Manage the list of inspections types within a daily log",
  },
  {
    id: "propertyTypes",
    title: "Property Types",
    type: "Management",
    description:
      "Select the property types and add custom types for properties in EstateSpace",
  },
  {
    id: "qualificationsAndExclusions",
    title: "Qualifications & Exclusions",
    type: "Documents",
    description:
      "Manage the default list of qualifications and exclusions for project financial Documents",
  },
  {
    id: "rateSheet",
    title: "Rate Sheet",
    type: "Financial",
    description: "Manage the default rate sheet for projects in EstateSpace",
  },
  {
    id: "spaceTypes",
    title: "Space Types",
    type: "Management",
    description: "Manage the spaces available across EstateSpace",
  },
  {
    id: "submittalTemplate",
    title: "Submittal Template",
    type: "Management",
    description: "Edit or change default submittal template for new projects",
  },
  {
    id: "submittalTypes",
    title: "Submittal Types",
    type: "Management",
    description: "Manage submittal types available projects in EstateSpace",
  },
  {
    id: "tags",
    title: "Tags",
    type: "Management",
    description: "Manage tags available across EstateSpace",
  },
  {
    id: "taxRates",
    title: "Tax Rates",
    type: "Financial",
    description: "Manage tax rates for FICA, FUTA, SUTA for your company",
  },
  {
    id: "rfiTemplate",
    title: "RFI Template",
    type: "Management",
    description: "Edit or change default RFI template for new projects",
  },
  // {
  //   type: "Management",
  //   title: "RFP Template",
  //   description: "Edit or change default RFP template for new projects",
  // },
  {
    type: "Management",
    title: "Standard Operating Procedures (SOP) Types",
    description: "Manage the SOP categories available in EstateSpace",
  },
  {
    id: "taskDuration",
    title: "Task Duration",
    type: "System",
    description:
      "Require members to enter the duration it took to complete a task",
  },
  {
    id: "timesheetPeriod",
    title: "Timesheet Period",
    type: "Financial",
    description:
      "Set the timesheet period that will apply for all timesheets in EstateSpace. This can not be changed once timesheets are active",
  },
  {
    id: "workflowTemplate",
    title: "Workflow Template",
    type: "Workflows",
    description:
      "Manage the default workflow template for new projects or properties in EstateSpace",
  },
  {
    id: "worldClock",
    title: "World Clock",
    type: "System",
    description: "Manage the timezones displayed across EstateSpace",
  },
  {
    id: "ratePerMile",
    title: "Rate Per Mile",
    type: "Financial",
    description: "Manage rate per mile across EstateSpace",
  },
  {
    id: "ticket",
    title: "Service Request Types",
    type: "Management",
    description: "Manage service request types",
  },
];

export const WORKFLOW_TAB_VIEWS = {
  active: "ACTIVE",
  resolved: "RESOLVED",
};

export const SUBMITTAL_TAB_VIEWS = {
  active: "ACTIVE",
  completed: "COMPLETED",
  draft: "DRAFT",
  void: "VOID",
};

// day order

/**
 * Page Constants
 */
export const MIN_PAGE_WIDTH = "903px";
/**
 * Page Constants
 */

// file attachment extensions
export const SOP_ATTACHMENT_EXTENSIONS = [
  ".pdf",
  ".xlsx",
  ".xls",
  ".docx",
  ".doc",
  ".pptx",
  ".ppt",
  ".csv",
  ".mp4",
  ".mp3",
  ".mov",
  ".webp",
  ".jpg",
  ".jpeg",
  ".png",
  ".svg",
  ".heic",
  ".heif",
];

// use for file uploaders as default file types
export const REQUEST_WORKFLOW_ATTACHMENT_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".heic",
  ".heif",
  ".mp4",
  ".png",
  ".mov",
  ".svg",
  ".pdf",
  ".docx",
  ".doc",
];

// document attachment extensions
export const DOCUMENT_ATTACHMENT_EXTENSIONS = [
  ".pdf",
  ".xlsx",
  ".xls",
  ".docx",
  ".doc",
  ".pptx",
  ".ppt",
  ".csv",
  ".txt",
];

// media attachment extensions
export const MEDIA_ATTACHMENT_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".mp4",
  ".png",
  ".mov",
  ".svg",
  ".heic",
  ".heif",
];

// media attachment extensions
export const EXPENSE_RECEIPT_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".pdf",
  ".heic",
  ".heif",
];

export const NON_SUPPORTED_BLOB_IMAGE_TYPES = ["image/heic", "image/heif"];

export const DEFAULT_MEDIA_EXTENSIONS =
  "image/*,.jpg,.jpeg,.png,.bmp,.gif,.heic,.heif,video/mp4,video/x-m4v,video/*";

export const PRIMARY_IMAGE_EXTENSIONS =
  "image/*,.jpg,.jpeg,.png,.bmp,.gif,.heic,.heif";

export const ACCEPTED_PRIMARY_IMAGE_TYPES =
  "image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/heic, image/heif, image/webp";

// file card constants
const defaultCardWidth = "205px";
const defaultCardMaxWidth = "205px";
const miniCardWidth = "124px";
const miniCardMaxWidth = "124px";
const defaultCardHeight = "240px";
const defaultCardMaxHeight = "240px";
const miniCardHeight = "144px";
const miniCardMaxHeight = "144px";
const defaultCardMargin = "0 12px 24px 0";
const defaultFolderMargin = "0 12px 40px 0";

export const fileCardStyle = {
  width: defaultCardWidth,
  maxWidth: defaultCardMaxWidth,
  height: defaultCardHeight,
  maxHeight: defaultCardMaxHeight,
  margin: defaultCardMargin,
};

export const fileCardMiniStyle = {
  width: miniCardWidth,
  maxWidth: miniCardMaxWidth,
  height: miniCardHeight,
  maxHeight: miniCardMaxHeight,
  // margin: defaultCardMargin,
};

export const folderCardStyle = {
  width: defaultCardWidth,
  maxWidth: defaultCardMaxWidth,
  margin: defaultFolderMargin,
};

export const uploadAreaStyle = {
  width: "300px",
  maxWidth: "300px",
  margin: defaultCardMargin,
};

export const adjustableUploadStyle = {
  minWidth: defaultCardWidth,
  margin: defaultCardMargin,
};

export const customModalStyles = {
  overlayStyle: {
    backgroundColor: "rgba(16, 16, 16, 0.3)",
  },
  contentStyle: {
    position: "fixed",
    padding: 0,
    top: "5%",
    left: "0",
    right: "0",
    maxHeight: "90vh",
    overflowX: "hidden",
    overflowY: "auto",
    maxWidth: "650px",
    margin: "auto",
    border: "none",
  },
  titleStyle: { color: "white" },
  // titleStyle: { color: "white", paddingLeft: "10px" },
  headerStyle: {
    backgroundColor: "rgb(99,99,99)",
    borderColor: "rgb(99,99,99)",
    top: "0",
    // paddingRight: "6px",
    position: "sticky",
    zIndex: "50",
  },
};

export const PayrollFrequencyOptions = [
  { label: "Weekly", value: "weekly" },
  { label: "Every two weeks", value: "bi-weekly" },
  { label: "Twice a month", value: "semi-monthly" },
  { label: "Monthly", value: "monthly" },
  { label: "Unspecified", value: "unspecified" },
];

export const AccountingMethodOptions = [
  { label: "Accrual", value: "accrual" },
  { label: "Cash", value: "cash" },
];

export const typeOfDurationOptions = [
  { label: "Days", value: "calendarDay" },
  { label: "Weeks", value: "week" },
  { label: "Months", value: "month" },
  { label: "Years", value: "year" },
];

export const CONTINGENGY_CSI_CODE = "50 10 00 - Contingency";
export const CONTINGENGY_LINE_ITEM_DESCRIPTION = "Project Contingency";

// budget types
export const BUDGET_COST_PLUS = "costPlus";
export const BUDGET_FIXED_FIRM = "fixedFirm";
export const BUDGET_TIME_AND_MATERIALS = "timeAndMaterials";

export const budgetTypeMap = {
  costPlus: "Cost Plus",
  costPlusL: "Cost Plus Lock GMP",
  fixedFirm: "Fixed Firm",
  fixedFirmL: "Locked Fixed Firm",
  timeAndMaterials: "Time and Materials",
};
export const budgetDocTypeMap = {
  [BUDGET_COST_PLUS]: [
    INVOICE,
    // REQUEST_FOR_INFORMATION,
    // REQUEST_FOR_PROPOSAL,
    PURCHASE_AUTHORIZATION,
  ],
  [BUDGET_FIXED_FIRM]: [],
  [BUDGET_TIME_AND_MATERIALS]: [
    INVOICE,
    // REQUEST_FOR_INFORMATION,
    // REQUEST_FOR_PROPOSAL,
    PURCHASE_AUTHORIZATION,
    PURCHASE_ORDER,
  ],
};
export const budgetLockedDocTypeMap = {
  [BUDGET_COST_PLUS]: [
    INVOICE,
    // REQUEST_FOR_INFORMATION,
    // REQUEST_FOR_PROPOSAL,
    PURCHASE_AUTHORIZATION,
    CHANGE_ORDER,
    CONTINGENCY_AUTHORIZATION,
  ],
  [BUDGET_FIXED_FIRM]: [
    INVOICE,
    // REQUEST_FOR_INFORMATION,
    // REQUEST_FOR_PROPOSAL,
    PURCHASE_ORDER,
    CHANGE_ORDER,
  ],
};

export const budgetTypesOptions = [
  { label: "Cost Plus", value: BUDGET_COST_PLUS },
  { label: "Fixed Firm", value: BUDGET_FIXED_FIRM },
  { label: "Time and Materials", value: BUDGET_TIME_AND_MATERIALS },
];

export const fileButtonPositionsMap = {
  above: "-top-1",
  below: "bottom-0",
};

export const PROJECT_STATUS_TYPES = {
  draft: "draft",
  active: "active",
  complete: "complete",
};

// document lineitem adjustment keys
export const docLineItemAdjustmentKeys = {
  [CHANGE_ORDER]: "coTotalPending",
  [CONTINGENCY_AUTHORIZATION]: "caAdjustment",
  [PURCHASE_AUTHORIZATION]: "paAdjustment",
  [PURCHASE_ORDER]: "poTotal",
};

export const calendarYOffset = 10;

// document history

// document status codes and display names
export const DOCUMENT_DISPLAY_STATUS = {
  open: "Open",
  locked: "Locked",
  approved: "Approved",
  requestResubmission: "Requested Resubmission",
  closed: "Closed",
  submitted: "Submitted",
  pendingClientAcceptance: "Pending Vendor Acceptance",
  pendingVendorAcceptance: "Pending Vendor Acceptance",
};

export const EXPENSE_STATUS = {
  open: "open",
  merged: "merged",
  billed: "billed",
};

export const calendarViewOptions = [
  { label: "Schedule", value: "schedule" },
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export const ATTRIBUTE_TYPES = [
  {
    label: "Text",
    value: "text-entry",
  },
  {
    label: "Number",
    value: "number-entry",
  },
  {
    label: "Date",
    value: "date-picker",
  },
  {
    label: "List",
    value: "ddl",
  },
  {
    label: "Checkbox",
    value: "check-box",
  },
  {
    label: "Number with Units",
    value: "number-entry-with-unit",
    hasUnits: true,
  },
];

// Message for a dropdown with no options
export const DEFAULT_NO_OPTION_MSG = "No options";
export const NO_ATTRIBUTE_OPTION_MSG = "No Matching Results";

export const USERS_HOOK_STATE = {
  loading: "loading",
  calling: "calling",
  loaded: "loaded",
};

export const REQUEST_EMPTY_STEP = {
  position: 0,
  name: "",
  description: "",
  submitter: "",
  startDate: {
    projected: new Date(),
    actual: new Date(),
  },
  duration: {
    projected: 10,
    actual: 10,
  },
  endDate: {
    projected: new Date(),
    actual: new Date(),
  },
  receiver: [{}],
  status: [
    "waiting",
    "approved",
    "approved-resubmission-required",
    "approved-resubmission-not-required",
    "revise-and-resubmit",
    "rejected",
  ],
  date: new Date(),
  note: "",
};

export const ASSET_EMPTY = {
  association: "",
  category: "",
  description: "",
  value: [],
  additionalInfo: [],
  locations: [],
  files: [],
  images: [],
  videos: [],
  links: [],
  documents: [],
  name: "",
  originalResource: {},
};

export const COMPANY_EMPTY = {
  address: [
    {
      use: "Physical",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
    {
      use: "Mailing",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
  ],
  mailingSameAsPhysical: false,
  name: {},
  contactPoint: [],
  kind: "company",
  new: true,
  isNewCompany: undefined,
  companyName: "",
  company: {
    valueType: "string",
    label: "",
    value: "",
  },
};

export const CONTACT_EMPTY = {
  address: [
    {
      use: "Physical",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
    {
      use: "Mailing",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
  ],
  mailingSameAsPhysical: false,
  invite: false,
  name: {
    firstName: "",
    lastName: "",
  },
  contactPoint: [],
  isNewCompany: undefined,
  kind: "nonmember",
  new: true,
  notes: "",
  title: "",
  company: {
    valueType: "reference",
    label: undefined,
    value: undefined,
  },
  companyName: undefined,
};

export const EXPENSE_EMPTY = {
  association: undefined,
  category: undefined,
  date: "",
  merchant: "",
  description: "",
  amount: "",
  billable: true,
  creditCard: true,
  note: "",
  receipts: [],
};

export const EVENT_EMPTY = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  status: "scheduled",
  invitees: [],
  association: "",
  projects: [],
  properties: [],
  files: [],
  recurrence: "",
  tags: [],
  isAllDay: false,
};

export const PROJECT_EMPTY = {
  id: null,
  name: "",
  resource: null,
  reference: null,
  description: "",
  projectType: undefined,
  metadata: null,
  goals: "",
  challenges: "",
  contractDate: null,
  startDate: null,
  endDate: null,
  contractAmount: null,
  rateSheet: {
    premiumRateDaysOfWeek: {
      sunday: true,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      holidays: true,
    },
    rates: [
      {
        selected: true,
        count: 0,
        id: "4fa7ca2c-ccb3-4779-85ab-9133db2cbdd1",
        category: "Senior Project Manager",
        ratePerHr: "180.00",
        rateOver40Hrs: "270.00",
        premiumRate: "450.00",
      },
      {
        selected: true,
        count: 0,
        id: "d56a20ae-c59e-4dc4-9e3d-5561a3636120",
        category: "Project Manager",
        ratePerHr: "140.00",
        rateOver40Hrs: "210.00",
        premiumRate: "350.00",
      },
      {
        selected: true,
        count: 0,
        id: "7a987024-db2d-4981-8c93-a9885bb08764",
        category: "Foreman",
        ratePerHr: "125.00",
        rateOver40Hrs: "188.00",
        premiumRate: "313.00",
      },
      {
        selected: true,
        count: 0,
        id: "d69e7a3b-052c-4312-9062-1b0428f70459",
        category: "Project Coordinator",
        ratePerHr: "100.00",
        rateOver40Hrs: "150.00",
        premiumRate: "250.00",
      },
      {
        selected: true,
        count: 0,
        id: "1c56124f-a7e7-4cae-9e7d-3090caf0a830",
        category: "Demo Rate Sheet",
        ratePerHr: "12.00",
        rateOver40Hrs: "12.00",
        premiumRate: "12.00",
      },
      {
        selected: true,
        count: 0,
        id: "969139bf-f35d-41b4-a6b5-6bb1b58d96d3",
        category: "Testing ES 2362",
        ratePerHr: "1111.00",
        rateOver40Hrs: "1111.00",
        premiumRate: "1111.00",
      },
    ],
  },
  retainage: [],
  hoursOfOperation: {
    monday: {
      active: true,
      from: "09:00",
      to: "14:30",
    },
    tuesday: {
      active: true,
      from: "09:00",
      to: "15:30",
    },
    wednesday: {
      active: true,
      from: "07:00",
      to: "15:00",
    },
    thursday: {
      active: false,
      from: "09:00",
      to: "12:00",
    },
    friday: {
      active: false,
      from: "09:00",
      to: "17:00",
    },
    saturday: {
      active: false,
      from: "09:00",
      to: "17:00",
    },
    sunday: {
      active: false,
      from: "09:00",
      to: "17:00",
    },
  },
  insurance: {
    requirements: [],
  },
  contractualFee: {},
  yearsOfWarranty: null,
  timeToNotify: {},
  allowanceFinePrint: "",
  qualificationsAndExclusions: [],
  members: [],
  weather: null,
  property: null,
  budget: null,
  schedule: null,
  address: [
    {
      use: "Physical",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    {
      use: "Mailing",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  ],
  mailingSameAsPhysical: true,
  threats: [],
  notifications: [],
  tasks: [],
  workflows: [],
  inventory: [],
  files: [],
  approvals: [],
  staffing: [],
  duration: "",
  durationType: "",
  relationships: null,
  vendor: null,
  budgetType: null,
  financesMasterList: false,
  contractsMasterList: false,
  isArchived: false,
  status: false,
  selectDates: true,
  images: [],
};

export const PROPERTY_EMPTY = {
  title: "",
  description: "",
  propertyType: undefined,
  address: [
    {
      use: "Physical",
      street: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    {
      use: "Mailing",
      street: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  ],
  mailingSameAsPhysical: true,
  hoursOfOperation: {
    monday: {
      active: true,
      from: "09:00",
      to: "14:30",
    },
    tuesday: {
      active: true,
      from: "09:00",
      to: "15:30",
    },
    wednesday: {
      active: true,
      from: "07:00",
      to: "15:00",
    },
    thursday: {
      active: false,
      from: "09:00",
      to: "12:00",
    },
    friday: {
      active: false,
      from: "09:00",
      to: "17:00",
    },
    saturday: {
      active: false,
      from: "09:00",
      to: "17:00",
    },
    sunday: {
      active: false,
      from: "09:00",
      to: "17:00",
    },
  },
  additionalInfo: [],
  insurance: [],
  files: [],
  members: [
    {
      user: "",
    },
  ],
  vendors: [],
  documents: [],
  originalResource: {},
};

export const REPORT_EMPTY = {
  title: "",
  outputType: "csv",
  tags: [],
  distribution: [],
  sources: [],
  dateRange: {
    option: "last7days",
  },
  repeat: undefined,
  sourceType: undefined,
};

export const SERVICE_REQUEST_EMPTY = {
  association: undefined,
  description: "",
  onBehalf: "",
  priority: "medium",
  issue: "",
  files: [],
  tasks: [],
  tags: [],
};

export const SOP_EMPTY = {
  edit: false,
  name: "",
  category: "",
  currentTags: [],
  description: "",
  dateCreated: "",
  lastUpdatedBy: "",
  steps: [],
  tags: [],
  files: [],
  links: [],
  originalResource: {},
};

export const SPACE_EMPTY = {
  name: "",
  id: "",
  images: [],
  files: [],
  videos: [],
  additionalInfo: [],
  area: {
    value: 0,
  },
  originalResource: {},
  level: undefined,
  type: undefined,
  // type: { label: undefined, value: undefined },
  isNewType: undefined,
};

export const SUBMITTAL_EMPTY = {
  isTemplate: false,
  isDefaultTemplate: false,
  customName: "",
  association: undefined,
  type: undefined,
  description: "",
  startDate: {
    actual: "",
  },
  duration: {
    numDays: 0,
    projected: "",
  },
  vendor: [],
  associatedArtifacts: [],
  ballInCourt: [],
  distribution: [],
  private: false,
  requestWorkflow: [
    {
      steps: [],
    },
  ],
  links: [],
  attachments: [],
  artifactFor: {},
  impacts: {},
  schedule: {
    description: "",
  },
  budget: {
    description: "",
  },
};

export const TASK_EMPTY = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  status: "scheduled",
  invitees: [],
  association: undefined,
  files: [],
  recurrence: "",
  steps: [],
  originalResource: {
    tags: [],
  },
  currentTags: [],
  timezone: guessTimeZone()?.label,
  duration: {},
  priority: "medium",
  tags: [],
  space: "",
};

export const WORKFLOW_EMPTY = {
  isTemplate: false,
  isDefaultTemplate: false,
  customName: "",
  association: "",
  type: undefined,
  description: "",
  startDate: {
    actual: "",
  },
  duration: {
    numDays: 0,
    projected: "",
  },
  vendor: [],
  associatedArtifacts: [],
  ballInCourt: [],
  distribution: [],
  private: false,
  requestWorkflow: [
    {
      steps: [],
    },
  ],
  links: [],
  attachments: [],
  artifactFor: {},
  impacts: {},
  schedule: {
    description: "",
  },
  budget: {
    description: "",
  },
};

export const WORKFLOW_EMPTY_STEP = {
  position: 0,
  name: "",
  description: "",
  submitter: "",
  startDate: {
    projected: new Date(),
    actual: new Date(),
  },
  duration: {
    projected: 5,
    actual: 5,
  },
  endDate: {
    projected: new Date(),
    actual: new Date(),
  },
  receiver: [{}],
  status: [
    "waiting",
    "approved",
    "approved-resubmission-required",
    "approved-resubmission-not-required",
    "revise-and-resubmit",
    "rejected",
  ],
  date: new Date(),
  note: "",
};

// Submittal Constants
export const SUBMITTAL_ROLES = [
  { label: "Approver", value: "approver" },
  { label: "Reviewer", value: "reviewer" },
  { label: "Submitter", value: "submitter" },
];

// Submittal Constants
export const WORKFLOW_ROLES = [
  { label: "Approver", value: "approver" },
  { label: "Assignee", value: "assignee" },
  { label: "Reviewer", value: "reviewer" },
  { label: "Submitter", value: "submitter" },
];

export const ASSET_CREATE_TAB_ORDER = [
  "association",
  "category",
  "assetName",
  "location",
  "space",
  "description",
  "cancelButton",
  "createButton",
];

// Default query stale cache time
// 6 min

// used as keys in react-query to store data in the cache

/**
 * Calendar Style Constants
 */

// Day of the month offset in calendar cell
export const CALENDAR_DATE_NUMBER_OFFSET = 35 * 0.2;

/**
 * Events start at 1/3 of the cell
 * - Makes room for date
 */
export const CALENDAR_MONTH_EVENT_DATE_OFFSET = 35 * 0.3;

export const CALENDAR_MONTH_EVENT_HEIGHT = 35 * 0.17;

/**
 * Offset width and x of event so it's within the cell
 */
export const CALENDAR_MONTH_BORDER_OFFSET = 0.25;

/**
 * Center text vertically
 */
export const CALENDAR_MONTH_TEXT_VERTICAL_CENTER =
  CALENDAR_MONTH_EVENT_HEIGHT / 2;

/**
 * MiniCalenar
 */

export const MINI_CALENDAR_PAD_X = 10;

export const MINICALENDAR_PAD_Y = 20;

export const MINI_CALENDAR_WIDTH = 254;

export const MINI_CALENDAR_CELL_DIMENSIONS = {
  width: MINI_CALENDAR_WIDTH / 7,
  height: 38,
};

export const MINI_CALENDAR_BODY_OFFSET = 49;

// App resource types
export const EVENT = "event";
export const TASK = "task";
export const PROPERTY = "property";
export const PROJECT = "project";
export const TICKET = "ticket";
export const CONTACTS = "contacts";
export const ASSET = "asset";
export const EXPENSE = "expense";
export const SOP = "sop";
export const REPORT = "report";

/**
 * MiniCalenar
 */

/**
 * Calendar Style Constants
 */

/**
 * Popover Comments
 */
export const COMMENT_MODAL_HEIGHT = 633;
export const COMMENT_MODAL_WIDTH = 505;

/**
 * Colors:
 * For now placing colors in this file, but it would be ideally to move to a styles/theme folder
 */

export const PRIMARY_DARK_GREEN = "#027D61";
export const INPUT_BG_GREEN = "#E8F9F5";
export const INPUT_BG_DISABLED = "#EEEEEE";
export const INPUT_BG_ENABLED = "#F9F9F9";
export const TASK_BLUE = "#076dcb";
export const CALENDAR_STROKE = "#d0d0d0";

/**
 * SR Priorities
 */
export const REQUEST_PRIORITIES = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

/**
 * SR Status
 */
export const SERVICE_REQUEST_STATUS = {
  closed: "Complete",
  open: "In Progress",
};

/**
 * Report types
 */
export const REPORT_EXPORT_TYPES = [{ label: "Excel", value: "csv" }];

/**
 * Chat
 */

// Errors
export const ERROR_LOADING_REPLIES = "ERR_01: Error loading reply thread.";
export const ERROR_SENDING_MESSAGE =
  "ERR_02: Error sending message, try again.";
export const ERROR_DISCONNECTED =
  "ERR_02: Client disconnected, attempting to reconnect.";

/**
 * Chat
 */

/**
 * Help
 */

export const HELP_TAB_OPTIONS = {
  GENERAL: "General",
  SECURITY: "Security",
  SUPPORT: "Support",
  ACCOUNT_AND_SETUP: "Account & Setup",
};

export const HELP_REQUEST_URL = "/api/Admin/help?platform=web";

/**
 * Date ranges
 */
export const DATE_RANGES_OPTIONS = [
  { id: "last7days", value: "last7days", label: "Last 7 days" },
  { id: "last14days", value: "last14days", label: "Last 14 days" },
  { id: "last30days", value: "last30days", label: "Last 30 days" },
  { id: "custom", value: "custom", label: "Custom Range" },
];

/**
 * Recurrence dropdown options
 */
export const recurrenceOptions = [
  { label: "Does not repeat", value: "does not repeat" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
  { label: "Custom Repetition", value: "custom repetition" },
];

// Global state change types
export const SET_TIMESHEET_STATE = "SET_TIMESHEET_STATE";

export const TS_PERIOD_UNLOCKED_MSG =
  "This setting will be locked once a Timesheet is saved to maintain data integrity.";

export const TS_PERIOD_LOCKED_MSG =
  "This setting is locked to maintain data integrity with existing Timesheet(s).";

export const APPROVED = "approved";
export const FAILED_TS_FETCH = "Failed to fetch approved timesheets";

export const TS_OVERVIEW = {
  MY_TIMESHEET: "My Timesheet",
  MY_TIMESHEET_ID: "my-timesheet",
  REQUEST_TIME_OFF: "Request Time Off",
  REQUEST_TIME_OFF_ID: "user-timeoff",
  TIMESHEET_APPROVAL: "Timesheet Approval",
  TIMESHEET_APPROVAL_ID: "approve-time",
  PAYROLL_APPROVAL: "Payroll Approval",
  PAYROLL_APPROVAL_ID: "approve-payroll",
  TIME_OFF_APPROVAL: "Time Off Approval",
  TIME_OFF_APPROVAL_ID: "approve-timeoff",
};

export const RSVP_STATUSES = {
  accept: "accept",
  maybe: "maybe",
  decline: "decline",
};

/* Constants for QuickAddToSpaceModal */
export const CHANGE_SPACE_NAME = "CHANGE_SPACE_NAME";
export const CHANGE_SPACE_ASSOCIATION = "CHANGE_SPACE_ASSOCIATION";
export const CHANGE_SPACE_LEVEL = "CHANGE_SPACE_LEVEL";
export const CHANGE_SPACE_TYPE = "CHANGE_SPACE_TYPE";
export const CHANGE_SPACE_AREA = "CHANGE_SPACE_AREA";
export const CHANGE_SPACE_IMAGE = "CHANGE_SPACE_IMAGE";

export const ENVIRONMENTS = {
  localhost: "localhost",
  feature: "feature",
  staging: "staging",
  production: "production",
};

/* Constants for Settings API key/subKey */
export const USER_SETTINGS_KEYS = {
  BILLING: {
    KEY: "billing",
    SUBKEY_1: "hasUserAcknowledgedPricingAlert",
  },
  RESOURCE_TABLE: {
    SUBKEY_1: "columns",
  },
};

export const ADMIN_ROLE_NAME = "Admin";

export const TIMESHEET_NO_RATES_ERROR =
  "Please add line items to your budget in order to bill time against this project.";
export const TIMESHEET_NO_PROJECTS_ERROR =
  "Please activate a project to start billing time.";
export const TIMESHEET_NO_CATEGORIES_ERROR =
  "No categories found for this rate.";
